import React, { useState, useEffect, ChangeEvent } from "react";
import { useApi } from "../../services/context/ApiContext";
import { useNavigate } from "react-router-dom";
import "../../styles/Art.css";
import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import Loading from "../../components/LoadingComponent";
import HeaderLoginComponent from "../../components/HeaderLoginComponent";
import { encrypt } from "../../utils/Crypto";
import ReactStars from "react-rating-stars-component";
import logored from "../../assets/logored.png";

interface GaleriaItem {
  _id: string;
  foto: string;
  nome_artista: string;
  nome_livro: string;
  endereco: string;
  descricao: string;
  uf: string;
  cidade: string;
}

const ArtList: React.FC = () => {
  const { dadosArtes } = useApi();
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [artesExibidas, setArtesExibidas] = useState<GaleriaItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("nome_livro");
  const [idBook, setIdBook] = useState<string | undefined>();
  const [rating, setRating] = useState<number>(5);

  const navigate = useNavigate();

  // Carregar a classificação do localStorage quando idBook mudar
  useEffect(() => {
    if (idBook) {
      const ratingLocalStorage = Number(
        localStorage.getItem(`rating ${idBook}`)
      );
      if (ratingLocalStorage) {
        setRating(ratingLocalStorage);
      } else {
        setRating(5); // Define o valor padrão se não houver classificação no localStorage
      }
    }
  }, [idBook]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFieldChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSearchField(event.target.value);
  };

  const filteredArtes =
    dadosArtes &&
    dadosArtes.filter((arte) => {
      const searchTermWithoutTilde = searchTerm.replace(/~/g, "");
      const fieldValue = arte && arte[searchField];

      if (fieldValue) {
        const fieldValueWithoutTilde = fieldValue
          .toUpperCase()
          .replace(/~/g, "");

        return (
          fieldValueWithoutTilde.includes(
            searchTermWithoutTilde.toUpperCase()
          ) ||
          (searchTermWithoutTilde.startsWith("~") &&
            fieldValueWithoutTilde.includes(
              searchTermWithoutTilde.substring(1).toUpperCase()
            ))
        );
      }
      return false;
    });

  useEffect(() => {
    if (dadosArtes.length > 0) {
      const inicio = (paginaAtual - 1) * 10;
      const fim = Math.min(inicio + 10, dadosArtes.length);
      setArtesExibidas(filteredArtes.slice(inicio, fim));
    }
  }, [dadosArtes, paginaAtual, filteredArtes]);

  const handleProximaPagina = () => {
    setPaginaAtual((prevPagina) => prevPagina + 1);
  };

  const handlePaginaAnterior = () => {
    setPaginaAtual((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  const handleArteClick = (arteId: string) => {
    navigate(`/arte/${encrypt(arteId as any)}`);
  };

  const redirecionarParaIa = () => {
    navigate("/ia");
  };

  const onRatingChange = (id: string, newRating: number) => {
    localStorage.setItem(`rating ${id}`, String(newRating));
    setIdBook(id);
    setRating(newRating);
  };

  return (
    <>
      {headerOrHeaderLogin()}
      {dadosArtes.length <= 0 ? (
        <Loading />
      ) : (
        <section className="art-main">
          <p className="user-list-header-artist">Aprecie as Histórias</p>
          <div className="input-container-adm">
            <input
              type="text"
              className="inputField-adm"
              placeholder={`🔍 Pesquisar por ... `}
              value={searchTerm}
              onChange={handleSearchChange}
            />

            <select
              value={searchField}
              onChange={handleSearchFieldChange}
              className="selectField-adm"
            >
              <option value="nome_livro">História</option>
              <option value="nome_artista">Escritor</option>
            </select>
          </div>
          <p className="user-list-header-artist">
            Clique na imagem para explorar o livro
          </p>
          <div className="card-grid">
            {artesExibidas.map((item: GaleriaItem) => (
              <div className="card" key={item._id}>
                <div
                  onClick={() => handleArteClick(item._id as any)}
                  style={{ backgroundColor: "transparent" }}
                >
                  <div>
                    <p className="description-p-art">{item.nome_livro}</p>
                  </div>
                  <img
                    src={item.foto}
                    alt={`Capa de ${item.nome_livro}`}
                    className="art-photo"
                  />
                  <div className="art-info">
                    <p
                      style={{
                        backgroundColor: "black",
                        color: "whitesmoke",
                      }}
                    >
                      {item.nome_artista}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                    marginTop: "-10px",
                    backgroundColor: "black",
                    flexDirection: "column",
                  }}
                  className="stars"
                >
                  <ReactStars
                    count={5}
                    value={
                      idBook === item._id
                        ? rating
                        : Number(localStorage.getItem(`rating ${item._id}`)) ||
                          5
                    }
                    onChange={(newRating) =>
                      onRatingChange(item._id, newRating)
                    }
                    size={25}
                    activeColor="#ff000083"
                  />{" "}
                  <img src={logored} alt="" className="logo-red-livros" />
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button
              className="btn-next"
              onClick={handlePaginaAnterior}
              disabled={paginaAtual === 1}
            >
              Anterior
            </button>
            <span className="pagina-numero">Página {paginaAtual}</span>
            <button
              className="btn-next"
              onClick={handleProximaPagina}
              disabled={artesExibidas.length < 8}
            >
              Próxima
            </button>
          </div>
          <section className="sobre-section-ia">
            <div className="text-sobre">
              <p className="titulo-sobre">
                <i>Cthulhu</i> Inteligência Artificial do 'Horror Story' para
                Escritores de Terror.
              </p>
              <p className="intro-text">
                Crie aqui suas ideias iniciais, descrições de personagens,
                cenários assustadores ou qualquer detalhe que você deseja
                incorporar em sua história de terror. Cthulhu irá ajudá-lo a
                transformar esses elementos em uma narrativa arrepiante e
                envolvente.
              </p>
            </div>
            <button className="sobre-btn-faca" onClick={redirecionarParaIa}>
              Conheça Cthulhu
            </button>
          </section>
        </section>
      )}
      <Footer />
    </>
  );
};

export default ArtList;
