import React from "react";

import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import "../../styles/About.css";
import Galeria from "../../components/GaleriaComponent";
import UserList from "../../components/UsersComponent";
import { useApi } from "../../services/context/ApiContext";
import Loading from "../../components/LoadingComponent";

import HeaderLoginComponent from "../../components/HeaderLoginComponent";
import { useNavigate } from "react-router-dom";
import "../../styles/Privacy.css";

const Privacy: React.FC = () => {
  const { dadosUsers } = useApi();
  const navigate = useNavigate();

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  const redirecionarParaJuntese = () => {
    navigate("/juntese");
  };

  return (
    <>
      {headerOrHeaderLogin()}
      <div className="cantainer-privacy-main">
        <div className="container-privacy">
          <h1 className="heading-privacy">
            TERMOS E CONDIÇÕES GERAIS DE USO — Horror Story
          </h1>
          <p>Esta política de Termos de Uso é válida a partir de 4/2024.</p>
          <p>
            Horror Story, website que integra comunidade de leitores e
            escritores de livros de terror, descreve, através deste documento,
            as regras de uso do site{" "}
            <a
              href="https://horrorstory.com.br"
              target="_blank"
              rel="noopener noreferrer"
              className="link-privacy"
            >
              https://horrorstory.com.br
            </a>
            .
          </p>
          <p>
            Ao navegar neste website, consideramos que você está de acordo com
            os Termos de Uso abaixo.
          </p>
          <p>
            Caso você não esteja de acordo com as condições deste contrato,
            pedimos que não faça mais uso deste website, muito menos cadastre-se
            ou envie os seus dados pessoais.
          </p>
          <p>
            Se modificarmos nossos Termos de Uso, publicaremos o novo texto
            neste website, com a data de revisão atualizada. Podemos alterar
            este documento a qualquer momento. Caso haja alteração significativa
            nos termos deste contrato, podemos informá-lo por meio das
            informações de contato que tivermos em nosso banco de dados ou por
            meio de notificações.
          </p>
          <p>
            A utilização deste website após as alterações significa que você
            aceitou os Termos de Uso revisados. Caso, após a leitura da versão
            revisada, você não esteja de acordo com seus termos, favor encerrar
            o seu acesso.
          </p>

          <h2 className="heading-privacy">Seção 1 - Usuário</h2>
          <p>
            A utilização deste website atribui de forma automática a condição de
            Usuário e implica a plena aceitação de todas as diretrizes e
            condições incluídas nestes Termos.
          </p>

          <h2 className="heading-privacy">
            Seção 2 - Adesão em conjunto com a Política de Privacidade
          </h2>
          <p>
            A utilização deste website acarreta a adesão aos presentes Termos de
            Uso e à versão mais atualizada da Política de Privacidade de Horror
            Story.
          </p>

          <h2 className="heading-privacy">Seção 3 - Condições de acesso</h2>
          <p>
            Em geral, o acesso ao website da Horror Story possui caráter
            gratuito e não exige prévia inscrição ou registro.
          </p>
          <p>
            Contudo, para usufruir de algumas funcionalidades, o usuário poderá
            precisar efetuar um cadastro, criando uma conta de usuário com login
            e senha próprios para acesso.
          </p>
          <p>
            É de total responsabilidade do usuário fornecer apenas informações
            corretas, autênticas, válidas, completas e atualizadas, bem como não
            divulgar o seu login e senha para terceiros.
          </p>
          <p>
            Partes deste website oferecem ao usuário a opção de publicar
            comentários em determinadas áreas. Horror Story não consente com a
            publicação de conteúdos que tenham natureza discriminatória,
            ofensiva ou ilícita, ou ainda infrinjam direitos de autor ou
            quaisquer outros direitos de terceiros.
          </p>
          <p>
            A publicação de quaisquer conteúdos pelo usuário deste website,
            incluindo mensagens e comentários, implica em licença não-exclusiva,
            irrevogável e irretratável, para sua utilização, reprodução e
            publicação pela Horror Story no seu website, plataformas e
            aplicações de internet, ou ainda em outras plataformas, sem qualquer
            restrição ou limitação.
          </p>

          <h2 className="heading-privacy">Seção 4 - Cookies</h2>
          <p>
            Informações sobre o seu uso neste website podem ser coletadas a
            partir de cookies. Cookies são informações armazenadas diretamente
            no computador que você está utilizando. Os cookies permitem a coleta
            de informações tais como o tipo de navegador, o tempo despendido no
            website, as páginas visitadas, as preferências de idioma, e outros
            dados de tráfego anônimos. Nós e nossos prestadores de serviços
            utilizamos informações para proteção de segurança, para facilitar a
            navegação, exibir informações de modo mais eficiente, e personalizar
            sua experiência ao utilizar este website, assim como para
            rastreamento online. Também coletamos informações estatísticas sobre
            o uso do website para aprimoramento contínuo do nosso design e
            funcionalidade, para entender como o website é utilizado e para
            auxiliá-lo a solucionar questões relevantes.
          </p>
          <p>
            Caso não deseje que suas informações sejam coletadas por meio de
            cookies, há um procedimento simples na maior parte dos navegadores
            que permite que os cookies sejam automaticamente rejeitados, ou
            oferece a opção de aceitar ou rejeitar a transferência de um cookie
            (ou cookies) específico(s) de um site determinado para o seu
            computador. Entretanto, isso pode gerar inconvenientes no uso do
            website.
          </p>
          <p>
            As definições que escolher podem afetar a sua experiência de
            navegação e o funcionamento que exige a utilização de cookies. Neste
            sentido, rejeitamos qualquer responsabilidade pelas consequências
            resultantes do funcionamento limitado deste website provocado pela
            desativação de cookies no seu dispositivo (incapacidade de definir
            ou ler um cookie).
          </p>

          <h2 className="heading-privacy">Seção 5 - Propriedade Intelectual</h2>
          <p>
            Todos os elementos de Horror Story são de propriedade intelectual da
            mesma ou de seus licenciados, exceto os livros de domínio público
            acessados através da no link{" "}
            <a
              href="https://www.lelivros.app/"
              className="link-privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Lê Livros
            </a>{" "}
            . Estes Termos ou a utilização do website não concede a você
            qualquer licença ou direito de uso dos direitos de propriedade
            intelectual da Horror Story ou de terceiros.
          </p>

          <h2 className="heading-privacy">
            Seção 6 - Links para sites de terceiros
          </h2>
          <p>
            Este website poderá, de tempos em tempos, conter links de hipertexto
            que redirecionarão você para sites das redes dos nossos parceiros,
            etc. Se você clicar em um desses links para qualquer um desses
            sites, lembre-se que cada site possui as suas próprias práticas de
            privacidade e que não somos responsáveis por essas políticas.
            Consulte as referidas políticas antes de enviar quaisquer Dados
            Pessoais para esses sites.
          </p>
          <p>
            Não nos responsabilizamos pelas políticas e práticas de coleta, uso
            e divulgação (incluindo práticas de proteção de dados) de outras
            organizações, tais como Facebook, Apple, Google, Microsoft, ou de
            qualquer outro desenvolvedor de software ou provedor de aplicativo,
            loja de mídia social, sistema operacional, prestador de serviços de
            internet sem fio ou fabricante de dispositivos, incluindo todos os
            Dados Pessoais que divulgar para outras organizações por meio dos
            aplicativos, relacionadas a tais aplicativos, ou publicadas em
            nossas páginas em mídias sociais. Nós recomendamos que você se
            informe sobre a política de privacidade e termos de uso de cada site
            visitado ou de cada prestador de serviço utilizado.
          </p>

          <h2 className="heading-privacy">Seção 7 - Prazos e alterações</h2>
          <p>O funcionamento deste website se dá por prazo indeterminado.</p>
          <p>
            O website no todo ou em cada uma das suas seções, pode ser
            encerrado, suspenso ou interrompido unilateralmente por Horror
            Story, a qualquer momento e sem necessidade de prévio aviso.
          </p>

          <h2 className="heading-privacy">Seção 8 - Dados pessoais</h2>
          <p>
            Durante a utilização deste website, certos dados pessoais serão
            coletados e tratados por Horror Story e/ou pelos Parceiros. As
            regras relacionadas ao tratamento de dados pessoais de Horror Story
            estão estipuladas na Política de Privacidade.
          </p>

          <h2 className="heading-privacy">
            Seção 9 - Obras Literárias de Domínio Público
          </h2>
          <p>
            O site Horror Story expõe obras literárias de domínio público,
            disponibilizando-as de forma organizada e sem fins lucrativos. Todas
            as obras de domínio público são exibidas gratuitamente, e o site
            apenas cataloga essas obras de forma sistematizada, semelhante a
            outros sites como LêLivros, que também as disponibilizam de forma
            gratuita.
          </p>

          <h2 className="heading-privacy">Seção 10 - Contato</h2>
          <p>
            Caso você tenha qualquer dúvida sobre os Termos de Uso, por favor,
            entre em contato pelo e-mail{" "}
            <a
              href="mailto:matheuspradoescritor@gmail.com"
              className="link-privacy"
            >
              matheuspradoescritor@gmail.com
            </a>
            .
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
