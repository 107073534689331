import React, { useState, useEffect, useRef } from "react";
import "../styles/Header.css";
import logo from "../assets/logocomnome1.webp";
import logo2 from "../assets/logored.png";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [loadingSrc, setLoadingSrc] = useState(logo2);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc) => (prevSrc === logo2 ? logo : logo2));
      setLoading(false);
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setLoadingSrc(logo);
    }, 2000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [logo, logo2]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = (event: MouseEvent) => {
    if (menuRef.current && buttonRef.current) {
      if (
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    }
  };

  const handleButtonClick = () => {
    toggleMenu();
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", closeMenu);
    } else {
      document.removeEventListener("mousedown", closeMenu);
    }

    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, [isMenuOpen]);

  const redirecionarParaSobre = (rota: string) => {
    navigate(rota);
  };

  return (
    <div className="header">
      <div className="menu-button" ref={buttonRef} onClick={handleButtonClick}>
        ☰
      </div>
      <section className="head-1">
        <h1 className="titulo-esquerda">
          <img
            className="logo"
            src={loadingSrc}
            style={{ backgroundColor: "transparent" }}
            alt="Logo Horror story"
          />
          <a href="/" className="link-sem-sublinhado">
            Horror Story
          </a>
        </h1>
        <nav ref={menuRef} className={isMenuOpen ? "open" : ""}>
          <ul className="lista-direita">
            <li
              onClick={() => redirecionarParaSobre("/")}
              className="header-list-hover"
            >
              Página Inicial
            </li>
            <li
              onClick={() => redirecionarParaSobre("/sobre")}
              className="header-list-hover"
            >
              Sobre
            </li>
            <li
              onClick={() => redirecionarParaSobre("/artistas")}
              className="header-list-hover"
            >
              Escritores
            </li>
            <li
              onClick={() => redirecionarParaSobre("/artes")}
              className="header-list-hover"
            >
              Livros
            </li>
            <li
              onClick={() => redirecionarParaSobre("/juntese")}
              className="header-list-hover"
            >
              Seja um Escritor
            </li>
            <li
              onClick={() => redirecionarParaSobre("/ia")}
              className="header-list-hover"
            >
              IA Cthulhu
            </li>
            <li
              onClick={() => redirecionarParaSobre("/login")}
              className="header-list-hover"
            >
              Login
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default Header;
