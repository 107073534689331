import React, { useState, useEffect } from "react";
import { useApi } from "../../services/context/ApiContext";
import Footer from "../../components/FooterComponent";
import Header from "../../components/HeaderComponent";
import Loading from "../../components/LoadingComponent";
import ProfilePage from "../../components/ProfileComponent";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const AdmUser: React.FC = () => {
  const { id, userId } = useParams<{ id?: string; userId?: string }>();
  const { fazerLogin, accessLogin } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      try {
        const accessTokenCookie = Cookies.get("jwtToken");
        const refreshTokenCookie = Cookies.get("refreshToken");

        if (refreshTokenCookie) {
          const decodedAccessToken = jwtDecode(refreshTokenCookie) as any;
          const currentTime = Date.now() / 1000;

          if (decodedAccessToken.exp > currentTime) {
            setIsAuthenticated(true);
            return;
          }
        }

        setIsAuthenticated(false);
      } catch (error) {
        console.error("Erro ao verificar autenticação:", error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, [accessLogin]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  return (
    <>
      <div className="art-admin-container">
        <ProfilePage />
      </div>
    </>
  );
};

export default AdmUser;
