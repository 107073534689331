import React, { useState, useEffect } from "react";
import { useApi } from "../services/context/ApiContext";

import Loading from "./LoadingComponent";
import "../styles/ArtAdmin.css";
import Popup from "./PopUpComponent";
import { useParams } from "react-router-dom";
import arteFoto from "../assets/profile-not-found.jpg";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../utils/Crypto";

interface Arte {
  _id: string;
  foto: string;
  nome_artista: string;
  nome: string;
  endereco: string;
  descricao: string;
  uf: string;
  cidade: string;
}
interface GaleriaItem {
  _id: any;
  foto: string;
  nome_artista: string;
  nome: string;
  endereco: string;
}

const ArtPostComponent: React.FC = () => {
  const { fazerLogin, dadosArtes, enviarDadosParaBackendArtPost, dadosUsers } =
    useApi();
  const { id } = useParams<{ id?: string }>();

  const [previewArt, setPreviewArt] = useState<any>();

  const [newArt, setNewArt] = useState<File | null>(null);

  const [showPopup, setShowPopup] = useState<any>();

  const [NovoNome, setNovoNome] = useState<string>("");
  const [NomeLivro, setNomeLivro] = useState<string>("");
  const [isEditingName, setIsEditingName] = useState(false);

  const [NovaDescricao, setNovaDescricao] = useState<string>("");
  const [Descricao, setDescricao] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);

  const [NovoEscritor, setNovoEscritor] = useState<string>("");
  const [Escritor, setEscritor] = useState<string>("");
  const [isEditingArtist, setIsEditingArtist] = useState(false);

  const [NovoLivro, setNovoLivro] = useState<string>("");
  const [Livro, setLivro] = useState<string>("");
  const [isEditingState, setIsEditingState] = useState(false);

  const [isEditingToken, setIsEditingToken] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const [newUsername, setNewUsername] = useState<string>("");
  const [originalUsername, setOriginalUsername] = useState<string>("");
  const [isEditingUsername, setIsEditingUsername] = useState(false);

  const [newId, setNewId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const idDecrypt = decrypt(id as string);
  const foundUser = dadosUsers.find((u) => u._id === idDecrypt);

  useEffect(() => {
    const usernameNew = foundUser && foundUser.username;
    console.log(usernameNew);
    setOriginalUsername(usernameNew);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const navigate = useNavigate();

  const [selectedArte, setSelectedArte] = useState<any>({
    username: originalUsername,
    nome_artista: NovoEscritor,
    nome_livro: NovoNome,
    foto: arteFoto,
    descricao: NovaDescricao,
    livro: NovoLivro,
  });
  const closePopup = () => {
    setShowPopup(undefined);
  };

  const handleSaveChanges = async () => {
    try {
      const { accessToken, refreshToken } = await fazerLogin({ email, senha });
      if (!refreshToken) {
        return "erro no token";
      }
      const dados = {
        username: foundUser.username,
        NovoEscritor,
        NovoNome,
        NovaDescricao,
        NovoLivro,
        newArt,
        accessToken,
      };
      await enviarDadosParaBackendArtPost(dados);
    } catch (error) {
      setShowPopup(false);
      console.error("Erro durante o login:", error);
    }
  };

  const toggleEditModeToken = async () => {
    try {
      setIsEditingToken(!isEditingToken);
      await handleSaveChanges();
      if (newId) {
        return setShowPopup(true);
      } else {
        return setShowPopup(false);
      }
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  const toggleEditModeState = () => {
    if (isEditingState) {
      setLivro(NovoLivro);
    } else {
      setNovoLivro(Livro);
    }
    setIsEditingState(!isEditingState);
  };

  const toggleEditModeArtist = () => {
    if (isEditingArtist) {
      setEscritor(NovoEscritor);
    } else {
      setNovoEscritor(Escritor);
    }
    setIsEditingArtist(!isEditingArtist);
  };

  const toggleEditModeName = () => {
    if (isEditingName) {
      setNomeLivro(NovoNome);
    } else {
      setNovoNome(NomeLivro);
    }
    setIsEditingName(!isEditingName);
  };

  const toggleEditMode = () => {
    if (isEditing) {
      setDescricao(NovaDescricao);
    } else {
      setNovaDescricao(Descricao);
    }
    setIsEditing(!isEditing);
  };

  const isAdmin = async ({ email, senha }: any) => {
    try {
      const { accessToken, refreshToken } = await fazerLogin({ email, senha });
      if (accessToken && refreshToken) {
        return true;
      }
    } catch (error) {
      console.error("Erro durante o login:", error);
    }
    return false;
  };

  useEffect(() => {
    console.log("007", foundUser && foundUser.username);
    if (idDecrypt) {
      if (foundUser) {
        const emailStorage: string = foundUser.email;
        setEmail(emailStorage);
        setOriginalUsername(foundUser.username);
        console.log(foundUser.username);
      } else {
        console.error("Usuário não encontrado");
      }
    }
    if (selectedArte) {
      setNomeLivro(selectedArte.nome_livro);
      setNovoNome(selectedArte.nome_livro);

      setDescricao(selectedArte.descricao);
      setNovaDescricao(selectedArte.descricao);

      setEscritor(selectedArte.nome_artista);
      setNovoEscritor(selectedArte.nome_artista);

      setLivro(selectedArte.livro);
      setNovoLivro(selectedArte.livro);
    } else {
      console.error("Livro não encontrado ");
    }
  }, [newId, id, selectedArte]);

  const renderizaImgNoFrontArte = (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      setNewArt(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewArt(reader.result as any);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!isAdmin) {
    return (
      <>
        <div className="content">
          <p>Você não tem permissão para acessar esta página.</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="art-admin-container">
        {isLoading && <Loading />}
        {dadosArtes.length <= 0 && <Loading />}
        <div className="container-home-admin">
          <div className="art-container-page">
            {selectedArte && (
              <div>
                <div className="user-info-adm">
                  {isEditingUsername ? (
                    <input
                      type="text"
                      name="username"
                      value={foundUser && foundUser.username}
                      className="username-input"
                      placeholder="Nome do Livro"
                    />
                  ) : (
                    <p style={{ color: "green" }}>
                      {foundUser && foundUser.username}
                    </p>
                  )}
                </div>

                <div className="user-info-adm">
                  {isEditingName ? (
                    <input
                      type="text"
                      name="nome_livro"
                      value={NovoNome}
                      onChange={(e) => setNovoNome(e.target.value)}
                      className="username-input"
                      placeholder="Nome da Livro"
                    />
                  ) : (
                    <p>{NomeLivro}</p>
                  )}
                  <button
                    onClick={toggleEditModeName}
                    className="email-edit-button"
                  >
                    {isEditingName
                      ? "Salvar"
                      : "Adicione um Nome para História"}
                  </button>
                </div>
                <label className="label-cover">
                  <input
                    type="file"
                    name="imagem"
                    accept="image/*"
                    onChange={(e) => renderizaImgNoFrontArte(e)}
                    className="cover-input"
                  />
                  <img
                    src={
                      !previewArt ? selectedArte.foto : previewArt.toString()
                    }
                    alt={`Capa de ${selectedArte.nome}`}
                    className="art-photo"
                  />
                </label>
                {isEditing ? (
                  <div>
                    Descrição
                    <textarea
                      rows={1}
                      name="descricao"
                      value={NovaDescricao}
                      onChange={(e) => setNovaDescricao(e.target.value)}
                      className="username-input"
                    />
                  </div>
                ) : (
                  <p>{Descricao}</p>
                )}
                <button onClick={toggleEditMode} className="edit-button">
                  {isEditing ? "Salvar" : "Adicione uma Descrição"}
                </button>

                <div className="user-info-adm">
                  {isEditingArtist ? (
                    <div>
                      <input
                        type="text"
                        name="nome_artista"
                        value={NovoEscritor}
                        onChange={(e) => setNovoEscritor(e.target.value)}
                        className="username-input"
                      />
                    </div>
                  ) : (
                    <p> Autor(a): {Escritor}</p>
                  )}
                  <button
                    onClick={toggleEditModeArtist}
                    className="email-edit-button"
                  >
                    {isEditingArtist
                      ? "Salvar"
                      : "Adicione nome(s) do Autor(a)"}
                  </button>
                </div>

                <div className="user-info-adm">
                  {isEditingState ? (
                    <div>
                      <textarea
                        rows={15}
                        name="livro"
                        value={NovoLivro}
                        onChange={(e) => setNovoLivro(e.target.value)}
                        className="username-input"
                      />
                    </div>
                  ) : (
                    <p> Livro: {Livro}</p>
                  )}
                  <button
                    onClick={toggleEditModeState}
                    className="email-edit-button"
                  >
                    {isEditingState ? "Salvar" : "Adicione seu Livro"}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="form-update-post">
            <p className="form-update">Digite sua Senha para continuar...</p>

            <p className="email-input">Email:</p>
            <input
              type="email"
              name="email-TOKEN"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="username-input"
              placeholder="email"
            />

            <p>Senha:</p>
            <input
              type="password"
              name="senha-TOKEN"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              className="username-input"
              placeholder="senha"
            />

            <button
              onClick={toggleEditModeToken}
              className="edit-button-finish"
            >
              Adiciona nova Livro
            </button>

            {showPopup !== undefined && (
              <Popup
                message={
                  showPopup
                    ? "Dados Adicionados com Sucesso"
                    : "Erro ao Adicionar Dados"
                }
                onClose={closePopup}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtPostComponent;
