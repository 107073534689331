import React, { useState, useEffect } from "react";
import "../../styles/Ia.css";
import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import logo from "../../assets/logo-cat.webp";
import logo2 from "../../assets/logo--cat-2.webp";
import enviar from "../../assets/env.png";
import check from "../../assets/check-mark.webp";
import logored from "../../assets/logored.png";

const Ia: React.FC = () => {
  const [prompt, setPrompt] = useState("");
  const [completion, setCompletion] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingSrc, setLoadingSrc] = useState<string>(logo);
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);
  const [numLines, setNumLines] = useState<number>(1);

  const calculateNumLines = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target;
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
    const scrollHeight = textarea.scrollHeight;
    const calculatedLines = Math.floor(scrollHeight / lineHeight);
    setNumLines(calculatedLines);
  };

  const [conversation, setConversation] = useState<
    { speaker: string; message: string }[]
  >([
    { speaker: "Cthulhu", message: "Olá, sou o Cthulhu, como posso ajudá-lo?" },
  ]);

  const saveToLocalStorage = (prompt: string, completion: string) => {
    localStorage.setItem("prompt", prompt);
    localStorage.setItem("completion", completion);
  };

  useEffect(() => {
    saveToLocalStorage(prompt, completion as string);
  }, [prompt, completion]);

  useEffect(() => {
    const comp = localStorage.getItem("completion");
    const prompt = localStorage.getItem("prompt");
    if (comp && prompt) {
      setCompletion(comp);
      setPrompt(prompt);
    }
  }, []);

  const apiKey = "yBqhLuJR8YPaTM3AEkJENQbFHEkvxD3u";

  const fetchCompletion = async (userPrompt: string) => {
    setIsLoading(true);
    setErrorMessage(null);

    const fullPrompt = `"lang": "pt" ${userPrompt}
     `;

    // Adiciona o prompt à conversa
    setConversation((prevConversation) => [
      ...prevConversation,
      { speaker: "Usuário", message: userPrompt },
    ]);

    try {
      const response = await fetch(
        "https://api.ai21.com/studio/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "jamba-instruct-preview",
            messages: [
              { content: `"lang": "pt" ${fullPrompt} `, role: "user" },
            ],
            n: 1,
            maxTokens: 4096,
            temperature: 0.9,
            topP: 1,
            stop: [],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();

      const newMessage = data.choices[0].message.content
        .replace(/Jamba/g, "Cthulhu")
        .replace(/AI21/g, "Horror Story");
      setCompletion(newMessage);
      setConversation((prevConversation) => [
        ...prevConversation,
        { speaker: "Cthulhu", message: newMessage },
      ]);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(event.target.value);
  };

  const handleButtonClick = () => {
    fetchCompletion(prompt);
    setPrompt("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      handleButtonClick();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc) => (prevSrc === logo ? logo2 : logo));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const handleCopy = (index: number, text: string) => {
    setCopiedIndex(index);
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text).then(() => {});
    }

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000); // Reseta o estado após 2 segundos
  };

  return (
    <div>
      <Header />
      <section className="art-main">
        <div className="container-ia-main">
          <span>
            Cthulhu: Inteligência Artificial para Escritores de Terror
          </span>
          <div className="conversation-container">
            {/* Renderiza todas as mensagens da conversa */}
            {conversation.map((msg, index) => (
              <div
                key={index}
                style={{
                  background: "none",
                  border: "none",
                  position: "relative",
                }}
              >
                {msg.speaker === "Cthulhu" && (
                  <button
                    onClick={() => handleCopy(index, msg.message)}
                    style={{
                      position: "absolute",
                      right: "0px",
                      top: "-8px",
                      backgroundColor: "#2e2929",
                      border: "1px solid red",
                      color: "white",
                      borderRadius: "5px",
                      padding: "5px 2px",
                      cursor: "pointer",
                    }}
                  >
                    {copiedIndex === index ? (
                      <div
                        style={{
                          backgroundColor: "#2e2929",
                          color: "white",
                        }}
                      >
                        Copiado{" "}
                        <img
                          src={check}
                          alt="sinal check"
                          style={{
                            width: "10px",
                            backgroundColor: "yellowgreen",
                            color: "white",
                            borderRadius: "50%",
                          }}
                        />{" "}
                      </div>
                    ) : (
                      "Copiar"
                    )}
                  </button>
                )}
                <p
                  style={{
                    backgroundColor: " #333",
                    color: "white",

                    display: "flex",
                    margin: "auto",
                    marginBottom: "15px",
                  }}
                >
                  <strong
                    style={{
                      backgroundColor: " #333",
                      color: "red",
                      opacity: "0.7",
                      marginRight: "5px",
                    }}
                  >
                    {msg.speaker}:
                  </strong>{" "}
                  {msg.message}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="textarea-container"> */}
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "auto",
                flexDirection: "column",
                paddingBottom: "25px",
                opacity: "0.3",
              }}
            >
              <img
                src={loadingSrc}
                alt="Loading"
                style={{
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                  paddingTop: "25px",
                }}
              />
              Carregando...
            </div>
          ) : (
            <textarea
              value={prompt}
              onChange={(event) => {
                handleInputChange(event);
                calculateNumLines(event);
              }}
              onKeyPress={handleKeyPress}
              placeholder="Digite aqui suas ideias iniciais, descrições de personagens, cenários assustadores ou qualquer detalhe que você deseja incorporar em sua história de terror. Cthulhu irá ajudá-lo a transformar esses elementos em uma narrativa arrepiante e envolvente."
              className="textarea-ia"
              disabled={isLoading}
            />
          )}
          {/* </div> */}
          <div
            style={{
              display: "flex",
              textAlign: "center",
              backgroundColor: "transparent",
            }}
          >
            Ao atualizar a página os dados serão perdidos
          </div>
          <button
            onClick={handleButtonClick}
            className="button-ia"
            disabled={prompt.length <= 0}
          >
            <img src={enviar} alt="botão de enviar" className="img-env" />
          </button>
        </div>
        <img src={logo} alt="logo" className="logo-ia" />
      </section>
      <Footer />
    </div>
  );
};

export default Ia;
