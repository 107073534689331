import React, { useState, ChangeEvent, useEffect } from "react";
import { useApi } from "../services/context/ApiContext";
import Footer from "./FooterComponent";
import Header from "./HeaderComponent";
import Loading from "./LoadingComponent";
import "../styles/ArtAdmin.css";
import { useParams } from "react-router-dom";
import { CustomNextArrow, CustomPrevArrow } from "./BtnComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Galeria.css";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../utils/Crypto";
import logo from "../assets/logo-cat.webp";
import logo2 from "../assets/logo--cat-2.webp";

interface Arte {
  _id: string;
  foto: string;
  nome_artista: string;
  nome_livro: string;
}

interface GaleriaItem {
  _id: any;
  foto: string;
  nome_artista: string;
  nome_livro: string;
}

const Galeria: React.FC = () => {
  const { fazerLogin, dadosArtes } = useApi();
  const { id } = useParams<{ id?: string }>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("nome_livro"); // Alterado de 'nome' para 'nome_livro'
  const [selectedArte, setSelectedArte] = useState<Arte | null>(null);
  const [larguraTotal, setLarguraTotal] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [newId, setNewId] = useState();
  const navigate = useNavigate();

  const isAdmin = async ({ email, senha }: any) => {
    try {
      const { accessToken, refreshToken } = await fazerLogin({ email, senha });
      if (accessToken && refreshToken) {
        return true;
      }
    } catch (error) {
      console.error("Erro durante o login:", error);
    }
    return false;
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFieldChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSearchField(event.target.value);
  };

  const handleArteClick = (arteId: number) => {
    setNewId(arteId as any);
    const clickedArte = dadosArtes.find((arte) => arte._id === arteId);
    setSelectedArte(clickedArte || null);
    const urlAtual = window.location.href;
    if (urlAtual.includes(`admuser`)) {
      navigate(`/admuser/${id}/arte/${arteId}/admuser`);
    } else {
      navigate(`/arte/${encrypt(arteId as any)}`);
    }
  };

  const filteredArtes =
    dadosArtes &&
    dadosArtes.filter((arte) => {
      const searchTermWithoutTilde = searchTerm.replace(/~/g, "");
      const fieldValue = arte && arte[searchField];

      if (fieldValue) {
        const fieldValueWithoutTilde = fieldValue
          .toUpperCase()
          .replace(/~/g, "");

        return (
          fieldValueWithoutTilde.includes(
            searchTermWithoutTilde.toUpperCase()
          ) ||
          (searchTermWithoutTilde.startsWith("~") &&
            fieldValueWithoutTilde.includes(
              searchTermWithoutTilde.substring(1).toUpperCase()
            ))
        );
      }
      return false;
    });

  useEffect(() => {
    const larguraDaTela = window.innerWidth;
    const handleResize = () => {
      const larguraOriginalDaImagem = 150;
      const numeroMaximoDeImagens = 5;

      const quantidadeDeImgsPorTela = larguraDaTela / larguraOriginalDaImagem;

      const numeroTotal = larguraDaTela / filteredArtes.length;

      const numeroDeImgsSemFiltro = Math.min(
        Math.floor(larguraDaTela / larguraOriginalDaImagem),
        numeroMaximoDeImagens
      );

      const numero = Math.min(
        Math.floor(larguraDaTela / +numeroTotal.toFixed(0)),
        numeroMaximoDeImagens
      );

      const realNumero =
        filteredArtes.length < quantidadeDeImgsPorTela
          ? numero
          : numeroDeImgsSemFiltro;

      setLarguraTotal(realNumero);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [filteredArtes.length, window.innerWidth]);

  useEffect(() => {
    // Crie um array para armazenar os URLs das imagens a serem pré-carregadas
    const imageUrlsToPreload: string[] = [];

    // Adicione os URLs das imagens de todas as artes filtradas ao array
    filteredArtes.forEach((item: GaleriaItem) => {
      imageUrlsToPreload.push(item.foto);
    });

    // Função para pré-carregar as imagens
    const preloadImages = () => {
      imageUrlsToPreload.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };

    // Pré-carregar as imagens apenas quando o componente for montado
    preloadImages();

    // Armazenar URLs das imagens no armazenamento local do navegador
    localStorage.setItem("cachedImages", JSON.stringify(imageUrlsToPreload));

    // Cleanup da função de pré-carregamento
    return () => {
      // Se desejar, você pode limpar recursos aqui
    };
  }, [filteredArtes]); // Atualize aqui para usar filteredArtes como dependência

  const [loadingSrc, setLoadingSrc] = useState(logo);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc: any) => (prevSrc === logo ? logo2 : logo));
    }, 275);

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: larguraTotal,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  if (!isAdmin) {
    return (
      <>
        <Header />
        <div className="content">
          <p>Você não tem permissão para acessar esta página.</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <div className="container-home-admin">
        <div className="input-container-adm">
          <input
            type="text"
            className="inputField-adm"
            placeholder={`🔍 Pesquisar por ... `}
            value={searchTerm}
            onChange={handleSearchChange}
          />

          <select
            value={searchField}
            onChange={handleSearchFieldChange}
            className="selectField-adm"
          >
            <option value="nome_livro">História</option>
            <option value="nome_artista">Escritor</option>
          </select>
        </div>
        {isLoading && <Loading />}
        {filteredArtes && filteredArtes.length === 0 && (
          <p className="galeria-item">Nenhum Livro encontrado</p>
        )}
        {
          <Slider {...settings} className="galeria">
            {filteredArtes.reverse().map((item: GaleriaItem) => (
              <div
                key={item._id}
                className="galeria-item"
                onClick={() => handleArteClick(item._id)}
              >
                <p className="nome-trabalho">{item.nome_livro}</p>
                {!isLoading ? (
                  <img
                    src={item.foto}
                    className="imagem-galeria"
                    alt={`Arte de ${item.nome_artista}`}
                  />
                ) : (
                  <div className="imagem-galeria-logo">
                    <img
                      src={loadingSrc}
                      style={{
                        width: "55px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                      alt={`Arte de ${item.nome_artista}`}
                    />
                  </div>
                )}

                <p className="nome-artista">
                  {" "}
                  Escritor(a): {item.nome_artista}
                </p>
              </div>
            ))}
          </Slider>
        }
      </div>
    </>
  );
};

export default Galeria;
