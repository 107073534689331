import React from "react";

import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import "../../styles/About.css";
import Galeria from "../../components/GaleriaComponent";
import UserList from "../../components/UsersComponent";
import { useApi } from "../../services/context/ApiContext";
import Loading from "../../components/LoadingComponent";

import logo from "../../assets//logocomnome1.webp";
import HeaderLoginComponent from "../../components/HeaderLoginComponent";
import { useNavigate } from "react-router-dom";

const About: React.FC = () => {
  const { dadosUsers } = useApi();
  const navigate = useNavigate();

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  const redirecionarParaJuntese = () => {
    navigate("/juntese");
  };

  return (
    <>
      {headerOrHeaderLogin()}
      {dadosUsers && dadosUsers.length > 0 ? (
        <div>
          <section className="about-section">
            <p className="about-h2"> Horror Story</p>
            <p className="about-p">
              O seu portal para o sobrenatural, o macabro e o desconhecido!
              Aqui, os corajosos escritores encontram um santuário para dar vida
              aos seus pesadelos mais arrepiantes, enquanto os leitores se
              aventuram por entre as sombras em busca de emoções intensas.
            </p>
            <p className="about-p">
              No Horror Story, as histórias são mais do que meras palavras no
              papel - são experiências que irão assombrar seus sonhos e desafiar
              sua coragem. De contos curtos a sagas épicas, cada narrativa é
              meticulosamente tecida para envolver sua mente e prender sua
              atenção até o último suspiro.
            </p>

            <p className="about-p">
              Junte-se a nós neste mundo de terror e suspense, onde cada página
              reserva uma nova surpresa e cada história tem o poder de fazer seu
              coração acelerar. Entre, se ousar, e deixe-se levar pelas
              profundezas do Horror Story.
            </p>

            <section className="sobre-section">
              <div className="text-sobre">
                <p className="titulo-sobre">
                  Seja um Autor(a) do 'Horror Story'
                </p>
                <p className="intro-text">
                  Junte-se a nós e publique suas histórias! Entre em nossa
                  comunidade aterrorizante e compartilhe seus contos sombrios
                  conosco. Se suas histórias forem aprovadas, elas serão
                  publicadas em nosso site, proporcionando uma plataforma para
                  você alcançar outros amantes do terror. Não perca essa
                  oportunidade de se juntar à nossa comunidade e deixar sua
                  marca no mundo do horror!
                </p>
              </div>
              <button
                className="sobre-btn-faca"
                onClick={redirecionarParaJuntese}
              >
                Seja um Autor(a)
              </button>
            </section>
          </section>
          <div className="container-logo">
            <img src={logo} alt="logo-about" className="logo-about" />
            <UserList />
            <p className="about-h2">Aprecie as Histórias </p>
            <section>
              <Galeria />
            </section>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default About;
