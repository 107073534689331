import React from "react";
import "../styles/Footer.css";
import CookiePopup from "./CookiePopup";
import EmailLogo from "../assets/email.png";
import InstagramLogo from "../assets/instagram.png";
import LinkedInLogo from "../assets/linkedin.png";
import YouTubeLogo from "../assets/youtube.webp";

const Footer: React.FC = () => {
  return (
    <section className="footer">
      <CookiePopup />
      <div className="container-footer clearfix">
        <div className="footer-data">
          <div className="footer-data-1">
            <h5>Entre em Contato</h5>
            <div>
              {" "}
              <a href="mailto:matheuspradoescritor@gmail.com">
                <img
                  src={EmailLogo}
                  alt=""
                  className="social-logo-profile-email"
                />
              </a>
              {/* <a href={user.linkedin} target="_blank" rel="noopener noreferrer"> */}
              {/* <img
                src={LinkedInLogo}
                alt="LinkedIn"
                className="social-logo-profile-email"
              /> */}
              {/* </a> */}
              {/* <a
                    href={user.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
              <a
                href="https://www.instagram.com/0horror_story/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={InstagramLogo}
                  alt="Instagram"
                  className="social-logo-profile-email"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCKkBGkW-v2t-uNhAs7Gv9_A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={YouTubeLogo}
                  alt="LinkedIn"
                  className="social-logo-profile-you"
                />
              </a>
              {/* </a> */}
            </div>
          </div>
          <div className="footer-data-1">
            <h5>Políticas</h5>
            <a href="/politica-de-privacidade">Segurança e privacidade</a>
          </div>
        </div>
        <div className="footer-content">
          <p>&copy; 2024 Comunidade Brasileira de Histórias de Terror.</p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
