import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";

import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const chave = "superhomembatatafritacomstrogonof";

interface ApiContextProps {
  dadosArtes: any[];
  dadosUsers: any[];
  erro: any;
  // getData: () => void;
  fazerLogin: any;
  enviarDadosParaBackend: any;
  enviarDadosParaBackendArt: any;
  enviarDadosParaBackendPost: any;
  enviarDadosParaBackendArtPost: any;
  deleteUsuario: any;
  deleteArte: any;
  dbData: any;
  accessToken: any;
  refreshToken: any;
  accessLogin: boolean;
  isOnUsers: [];
  isOnBooks: [];
}

const ApiContext = createContext<ApiContextProps | null>(null);

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [dadosArtes, setDadosArtes] = useState<any[]>([]);
  const [dadosUsers, setDadosUsers] = useState<any[]>([]);
  const [isOnUsers, setIsOnUsers] = useState<[]>([]);
  const [isOnBooks, setIsOnBooks] = useState<[]>([]);

  const [dadosHistorias, setDadosHistorias] = useState<any[]>([]);
  const [dadosEscritores, setDadosEscritores] = useState<any[]>([]);
  const [accessLogin, setAccessLogin] = useState<boolean>(false);

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  ///////////////////////////////
  const dataUsers = [
    {
      _id: "65cc409c09fcaa82cbd0c045",
      username: "matheusprado007",
      name: "Matheus Prado",
      email: "matheuspradodeveloper@gmail.com",
      senha: "$2b$10$4Iex/qCN70xzJ24NeATRK.njIWFcxb/FAlpb2npRNT8s7omZNj.P.",
      descricao_perfil:
        "Desenvolvedor web Full Stack, especializado em React e Node.js, apaixonado por tecnologia e inovação.",
      foto_perfil:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/c3238ca2-8a6f-487f-bf2f-5c50c2f3413f.jpg",
      foto_capa:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/ef5c6e92-3835-424f-9e63-56f2a14e61e2.jpg",
      linkedin: "https://www.linkedin.com/in/matheus--prado/",
      instagram: "https://www.instagram.com/mathewp71/",
      administrador: true,
      descricao_curta: "Escritor e Programador",
      __v: 0,
    },
    {
      _id: "65fe7641fe0059bfbda4d1c3",
      username: "lovecraft",
      name: "Howard Phillips Lovecraft",
      email: "Lovecraft@lovecraft.com",
      senha: "$2b$10$vNIdF5uxnHcxNRw3fcRLGe3nB4u.ci/RrRSblYjRWpJSkbG4Rc2g6",
      descricao_perfil:
        "Howard Phillips Lovecraft, mais conhecido como H. P. Lovecraft, foi um escritor norte-americano. Ele é conhecido por seus contos de horror, ficção científica e fantasia, que formaram a base do que hoje é chamado de horror cósmico, uma subcategoria do terror que enfatiza o horror da insignificância humana diante do cosmos.",
      foto_perfil:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/fe6cff75-764b-462d-8ab9-906bbf53d99c.jpg",
      foto_capa:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/fd5b244e-25b3-43c8-b716-61e473bd0a2e.jpg",
      linkedin: "nt",
      instagram: "nt",
      administrador: false,
      descricao_curta: "Criador do Horror Cósmico",
      __v: 0,
    },
    {
      _id: "66130f903445053f993bab69",
      username: "allanPoe",
      name: "Edgar Allan Poe",
      email: "poe@poe.com",
      senha: "$2b$10$2i0uE2iHizd1SzmG1fC5J.gjWy5gyuibybDwpvbSyj5ly6FeKtncK",
      descricao_perfil:
        "Edgar Allan Poe (nascido Edgar Poe; 19 de janeiro de 1809 - 7 de outubro de 1849) foi um autor, poeta, editor e crítico literário americano. Poe é mais conhecido por suas histórias de mistério e macabras, e é considerado o inventor do gênero de detetive moderno, além de ser um dos precursores do terror psicológico e da literatura gótica.",
      foto_perfil:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/970eceaf-9d6f-4a5c-877d-47829e87e054.jpg",
      foto_capa:
        "https://storage.googleapis.com/rastro-urbano.appspot.com/a9bc6176-2640-4414-90ff-765f9329ac0f.jpg",
      linkedin: "NT",
      instagram: "NT",
      administrador: false,
      descricao_curta: "Autor, poeta, editor e crítico literário.",
      __v: 0,
    },
  ];

  /////////////////////////////////

  const [erro, setErro] = useState<any | null>(null);

  async function deleteArte(dados: any) {
    const url = `https://api-rastro-urbano.onrender.com/upload/deletearte/${dados.id}`;

    const headers = {
      Authorization: `Bearer ${dados.token}`,
    };

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers,
      });

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();

      console.log("Arte removida com sucesso:", resultado);

      if (resultado._id) {
        return resultado._id;
      } else {
        throw new Error("Resposta da API não contém um ID válido.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
    }
  }

  async function deleteUsuario(dados: any) {
    const url = `https://api-rastro-urbano.onrender.com/upload/deleteuser/${dados.id}`;

    const headers = {
      Authorization: `Bearer ${dados.token}`,
    };

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers,
      });

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();

      console.log("Usuário removido com sucesso:", resultado);

      if (resultado._id) {
        return resultado._id;
      } else {
        throw new Error("Resposta da API não contém um ID válido.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
    }
  }

  async function enviarDadosParaBackendArtPost(dados: any) {
    // if (dados) {
    //   console.log(dados.username);
    //   console.log(dados.NovoEscritor);
    //   console.log(dados.NovoNome);
    //   console.log(dados.NovaDescricao);
    //   console.log(dados.NovoLivro);
    //   console.log(dados.refreshToken);
    //   console.log(dados.newArt);
    // }

    const url = "https://api-rastro-urbano.onrender.com/upload/createArte";

    const headers = {
      Authorization: `Bearer ${dados.accessToken}`,
    };

    const formData = new FormData();
    formData.append("username", dados.username);
    formData.append("nome_artista", dados.NovoEscritor);
    formData.append("nome_livro", dados.NovoNome);
    formData.append("descricao", dados.NovaDescricao);
    formData.append("pdf", dados.NovoLivro);

    formData.append("imagem", dados.newArt);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: formData,
      });

      if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();

      console.log("Dados enviados com sucesso:", resultado);

      if (resultado._id) {
        return resultado._id;
      } else {
        throw new Error("Resposta da API não contém um ID válido.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
      throw error;
    }
  }

  async function enviarDadosParaBackendPost(dados: any) {
    const url = `https://api-rastro-urbano.onrender.com/upload/createuser`;

    const headers = {
      Authorization: `Bearer ${dados.accessToken}`,
    };

    const formData = new FormData();
    formData.append("name", dados.newName);
    formData.append("username", dados.newUsername);
    formData.append("descricao_perfil", dados.newDescription);
    formData.append("email", dados.newEmail);
    formData.append("senha", dados.newPassword);
    formData.append("linkedin", dados.newLinkedin);
    formData.append("instagram", dados.newInstagram);
    formData.append("administrador", dados.newAdm);
    formData.append("descricao_curta", dados.texto);

    // Adicione os arquivos
    formData.append("foto_capa", dados.newCapa);
    formData.append("foto_perfil", dados.newPerfil);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();
      console.log("Dados atualizados com sucesso:", resultado);
      return `Dados atualizados com sucesso:`;
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
    }
  }

  async function enviarDadosParaBackendArt(dados: any) {
    const url = `https://api-rastro-urbano.onrender.com/upload/updatearte/${dados.id}`;

    if (dados.accessToken) {
      console.log(dados.accessToken);
    }

    const headers = {
      Authorization: `Bearer ${dados.accessToken}`,
    };

    const formData = new FormData();

    formData.append("nome_artista", dados.novoEscritor);
    formData.append("nome_livro", dados.novoNomeLivro);
    formData.append("livro", dados.novoLivro);
    formData.append("descricao", dados.novaDescricao);
    formData.append("username", dados.novoUsername);

    // Adicione os arquivos
    formData.append("imagem", dados.novaCapaLivro);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();
      console.log("Dados atualizados com sucesso:", resultado);
      return `Dados atualizados com sucesso:`;
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
    }
  }

  async function enviarDadosParaBackend(dados: any) {
    const url = `https://api-rastro-urbano.onrender.com/upload/updateuser/${dados.id}`;
    // console.log(dados.id);

    const headers = {
      Authorization: `Bearer ${dados.accessToken}`,
    };

    const formData = new FormData();

    formData.append("name", dados.newName);
    formData.append("username", dados.newUsername);
    formData.append("descricao_perfil", dados.newDescription);
    formData.append("email", dados.newEmail);
    formData.append("linkedin", dados.newLinkedin);
    formData.append("instagram", dados.newInstagram);
    formData.append("administrador", dados.newAdm);
    formData.append("descricao_curta", dados.texto);

    if (dados.password) {
      formData.append("senha", dados.newPassword);
    }

    // Adicione os arquivos
    formData.append("foto_capa", dados.newCapa);
    formData.append("foto_perfil", dados.newPerfil);

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers,
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Erro no servidor: ${response.statusText}`);
      }

      const resultado = await response.json();
      if (!resultado) {
        console.log(`erro Result: ${resultado}`);
      }
      console.log("Dados atualizados com sucesso:", resultado);
      return `Dados atualizados com sucesso:`;
    } catch (error) {
      console.error("Erro ao enviar dados para o backend:", error);
    }
  }

  const fazerLogin = async ({ email, senha }: any) => {
    try {
      // Validar entrada do usuário
      if (!email || !senha) {
        throw new Error("Por favor, insira um email e uma senha.");
      }

      // console.log("Iniciando a requisição de login...");

      const respostaLogin = await fetch(
        "https://api-rastro-urbano.onrender.com/upload/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            senha: senha,
          }),
        }
      );

      // console.log("Resposta da requisição recebida.");

      if (!respostaLogin.ok) {
        const errorResponse = await respostaLogin.json();
        console.error("Erro na resposta do login:", errorResponse.message);
        return { notOk: true, errorResponse: errorResponse.message };
      }

      const { accessToken, refreshToken } = await respostaLogin.json();

      // Definir tempo de expiração dos cookies
      const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
      const tokencripto =
        // Configurar cookies com sinalizadores de segurança
        Cookies.set("jwtToken", accessToken, {
          expires,
          secure: true,
        });
      // console.log("Cookie jwtToken definido.");

      Cookies.set("refreshToken", refreshToken, {
        expires,
        secure: true,
      });
      // console.log("Cookie refreshToken definido.");

      // Configurar renovação automática do token

      return { accessToken, refreshToken, notOk: false };
    } catch (error: any) {
      console.error("Erro durante a requisição POST de login:", error);
      throw error;
    }
  };

  const [dbDataLoaded, setDbDataLoaded] = useState(false);
  const [dbData, setDbDatas] = useState(false);

  const fetchDataFromDB = async () => {
    try {
      const fetchOptions = {
        method: "GET",
      };

      const [respostaArtes, respostaUsers] = await Promise.all([
        fetch(
          "https://api-rastro-urbano.onrender.com/upload/artes",
          fetchOptions
        ),
        fetch(
          "https://api-rastro-urbano.onrender.com/upload/users",
          fetchOptions
        ),
      ]);

      if (!respostaArtes.ok || !respostaUsers.ok) {
        throw new Error("Erro ao obter dados");
      }

      const dadosJsonArtes = await respostaArtes.json();
      const dadosJsonUsers = await respostaUsers.json();

      // console.log(dadosJsonArtes);
      // console.log(dadosJsonUsers);

      // Atualize os estados com os novos dados do banco de dados
      setDadosArtes(dadosJsonArtes);
      setDadosUsers(dadosJsonUsers);

      setIsOnUsers(dadosJsonUsers);
      setIsOnBooks(dadosJsonArtes);

      // Indique que os dados do banco de dados foram carregados
      setDbDataLoaded(true);

      const dadosFiltradosUsers =
        dadosJsonUsers &&
        dadosJsonUsers?.map((user: any) => {
          const { email, senha, ...dadosRestantes } = user;
          return dadosRestantes;
        });

      const dadosCriptografadosArtes = CryptoJS.AES.encrypt(
        JSON.stringify(dadosJsonArtes),
        chave
      ).toString();
      const dadosCriptografadosUsers = CryptoJS.AES.encrypt(
        JSON.stringify(dadosJsonUsers),
        chave
      ).toString();

      // Armazenar os dados no IndexedDB
      const openRequest = window.indexedDB.open("justnice", 1);

      openRequest.onerror = function (event: any) {
        console.error("Erro ao abrir o banco de dados:", event.target.error);
      };

      openRequest.onupgradeneeded = function (event: any) {
        const db = event.target.result;
        db.onerror = function (event: any) {
          console.error(
            "Erro durante a atualização do banco de dados:",
            event.target.error
          );
        };

        if (db.objectStoreNames.contains("nice")) {
          db.deleteObjectStore("nice");
        }

        const objectStore = db.createObjectStore("nice", { keyPath: "id" });
      };

      openRequest.onsuccess = function (event: any) {
        const db = event.target.result;
        const transaction = db.transaction(["nice"], "readwrite");
        const objectStore = transaction.objectStore("nice");

        const putRequestArtes = objectStore.put({
          id: "007",
          dados: dadosCriptografadosArtes,
        });
        const putRequestUsers = objectStore.put({
          id: "bond",
          dados: dadosCriptografadosUsers,
        });

        putRequestArtes.onerror = function (event: any) {
          console.error(
            "Erro ao adicionar/atualizar dados das Artes no IndexedDB:",
            event.target.error
          );
          transaction.abort();
        };

        putRequestUsers.onerror = function (event: any) {
          console.error(
            "Erro ao adicionar/atualizar dados dos Usuários no IndexedDB:",
            event.target.error
          );
          transaction.abort();
        };

        transaction.oncomplete = function () {
          // console.log(
          //   "Dados armazenados/atualizados com sucesso no IndexedDB."
          // );
          setDbDataLoaded(true);
        };

        transaction.onerror = function (event: any) {
          console.error(
            "Erro durante a transação no IndexedDB:",
            event.target.error
          );
        };
      };
    } catch (error) {
      console.error("Erro durante a requisição GET de dados:", error);
    }
  };

  const fetchDataFromLocalStorage = () => {
    try {
      const openRequest = window.indexedDB.open("justnice", 1);

      openRequest.onupgradeneeded = function (event: any) {
        const db = event.target.result;
        const objectStore = db.createObjectStore("nice", { keyPath: "id" });
      };

      openRequest.onsuccess = function (event: any) {
        const db = event.target.result;
        const transaction = db.transaction(["nice"], "readwrite");
        const objectStore = transaction.objectStore("nice");

        const getRequestArtes = objectStore.get("007");
        const getRequestUsers = objectStore.get("bond");

        getRequestArtes.onsuccess = function (event: any) {
          const encryptedArtes = event.target.result;

          // Verificar se os dados existem antes de tentar descriptografá-los
          if (encryptedArtes) {
            const decryptedArtes = CryptoJS.AES.decrypt(
              encryptedArtes.dados,
              chave
            ).toString(CryptoJS.enc.Utf8);

            const dadosArtes = JSON.parse(decryptedArtes);
            setDadosArtes(dadosArtes);
          } else {
            setDadosArtes(dadosArtes);
            console.error("Os dados das Artes não foram encontrados no IDB.");
          }
        };

        getRequestUsers.onsuccess = function (event: any) {
          const encryptedUsers = event.target.result;
          setDbDatas(encryptedUsers);
          if (encryptedUsers) {
            const decryptedUsers = CryptoJS.AES.decrypt(
              encryptedUsers.dados,
              chave
            ).toString(CryptoJS.enc.Utf8);

            const dadosUsers = JSON.parse(decryptedUsers);

            setDadosUsers(dadosUsers);
          } else {
            setDadosUsers(dataUsers);
            console.error(
              "Os dados dos Usuários não foram encontrados no IDB."
            );
          }
        };

        transaction.oncomplete = function () {
          // console.log("ok");
        };

        transaction.onerror = function (event: any) {
          console.error(
            "Erro ao realizar operação de leitura no IDB:",
            event.target.error
          );
        };
      };
    } catch (error) {
      console.error("Erro ao obter dados do S:", error);
    }
  };

  useEffect(() => {
    fetchDataFromDB();
  }, []);

  useEffect(() => {
    if (!dbDataLoaded) {
      fetchDataFromLocalStorage();
    }
  }, [dbDataLoaded]);

  const contextValue: ApiContextProps = {
    dadosArtes,
    dadosUsers,
    erro,
    dbData,
    fazerLogin,
    enviarDadosParaBackend,
    enviarDadosParaBackendArt,
    enviarDadosParaBackendPost,
    enviarDadosParaBackendArtPost,
    deleteUsuario,
    deleteArte,
    accessToken,
    refreshToken,
    accessLogin,
    isOnUsers,
    isOnBooks,
  };

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi deve ser usado dentro de um ApiProvider");
  }
  return context;
};
