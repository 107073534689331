import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../services/context/ApiContext";
import "../styles/ArtistList.css";
import { useParams } from "react-router-dom";
import { CustomNextArrow, CustomPrevArrow } from "./BtnComponent";
import Slider from "react-slick";
import Loading from "./LoadingComponent";
import { encrypt } from "../utils/Crypto";
import logo from "../assets/logo-cat.webp";
import logo2 from "../assets/logo--cat-2.webp";

const Artist: React.FC = () => {
  const { dadosUsers } = useApi();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [user, setUser] = useState<any>();
  const [idParams, setIdPrams] = useState<any>();
  const [larguraTotal, setLarguraTotal] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const navigateToProfile = (userId: string) => {
    const urlAtual = window.location.href;

    if (urlAtual.includes("in")) {
      navigate(`/profile/in/${encrypt(userId)}/${id}`);
    } else {
      navigate(`/profile/${encrypt(userId)}`);
    }
  };

  const adm = dadosUsers;

  useEffect(() => {
    setIdPrams(id);
  }, [id]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredArtist =
    adm &&
    adm.filter((artist) => {
      const searchTermWithoutTilde = searchTerm.replace(/~/g, "");
      const fieldValueWithoutTilde = artist.name
        .toUpperCase()
        .replace(/~/g, "");

      return (
        fieldValueWithoutTilde.includes(searchTermWithoutTilde.toUpperCase()) ||
        (searchTermWithoutTilde.startsWith("~") &&
          fieldValueWithoutTilde.includes(
            searchTermWithoutTilde.substring(1).toUpperCase()
          ))
      );
    });

  useEffect(() => {
    const larguraDaTela = window.innerWidth;
    const handleResize = () => {
      const larguraOriginalDaImagem = 150;
      const numeroMaximoDeImagens = 6;

      const quantidadeDeImgsPorTela = larguraDaTela / larguraOriginalDaImagem;

      const numeroTotal = larguraDaTela / filteredArtist.length;

      const numeroDeImgsSemFiltro = Math.min(
        Math.floor(larguraDaTela / larguraOriginalDaImagem),
        numeroMaximoDeImagens
      );

      const numero = Math.min(
        Math.floor(larguraDaTela / +numeroTotal.toFixed(0)),
        numeroMaximoDeImagens
      );

      const realNumero =
        filteredArtist.length < quantidadeDeImgsPorTela
          ? numero
          : numeroDeImgsSemFiltro;

      setLarguraTotal(realNumero);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 50);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [filteredArtist.length, window.innerWidth]);

  useEffect(() => {
    // Crie um array para armazenar os URLs das imagens a serem pré-carregadas
    const imageUrlsToPreload: string[] = [];

    // Adicione os URLs das imagens de todos os artistas filtrados ao array
    filteredArtist.forEach((item: any) => {
      imageUrlsToPreload.push(item.foto_perfil);
    });

    // Função para pré-carregar as imagens
    const preloadImages = () => {
      imageUrlsToPreload.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };

    // Pré-carregar as imagens apenas quando o componente for montado
    preloadImages();

    // Armazenar URLs das imagens no armazenamento local do navegador
    localStorage.setItem(
      "cachedArtistImages",
      JSON.stringify(imageUrlsToPreload)
    );

    // Cleanup da função de pré-carregamento
    return () => {
      // Se desejar, você pode limpar recursos aqui
    };
  }, [filteredArtist]); // Atualize aqui para usar filteredArtist como dependência

  const [loadingSrc, setLoadingSrc] = useState(logo); // define a primeira imagem como padrão

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc: any) => (prevSrc === logo ? logo2 : logo)); // alterna entre as imagens
    }, 275); // alterna a cada segundo

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: larguraTotal,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const dataFake = [
    loadingSrc,
    loadingSrc,
    loadingSrc,
    loadingSrc,
    loadingSrc,
    loadingSrc,
  ];

  return (
    // className="profile-container"
    <div className="container-artists-list">
      {/* <HeaderAdmin /> */}
      <div className="input-container-adm">
        <input
          type="text"
          className="inputField-adm-artist"
          placeholder={`🔍  Pesquisar por Escritor... `}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {!filteredArtist ||
        (filteredArtist.length === 0 && (
          <p className="galeria-item">Nenhum(a) Escritor(a) encontrado(a)</p>
        ))}
      <div className="user-list-container-artist">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <p className="user-list-header-artist">Conheça os Escritores</p>
            <div className="galeria-artist-list-container">
              <Slider {...settings} className="galeria">
                {filteredArtist.map((item: any) => (
                  <div
                    key={item._id}
                    className="galeria-item-list"
                    onClick={() => navigateToProfile(item._id)}
                  >
                    {item.foto_perfil ? (
                      <img
                        src={item.foto_perfil}
                        className="user-avatar-artist"
                        alt={`Arte de ${item.username}`}
                      />
                    ) : (
                      <div className="user-avatar-artist">
                        <img
                          src={loadingSrc}
                          style={{
                            width: "55px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "auto",
                          }}
                          alt={`Arte de ${item.nome_artista}`}
                        />
                      </div>
                    )}

                    <p className="nome-user-artist">{item.name}</p>
                    {/* <span className="nome-user-artist">
                      • {item.descricao_curta}
                    </span> */}
                  </div>
                ))}
              </Slider>
            </div>
          </>
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Artist;
