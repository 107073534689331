import React from "react";
import { useApi } from "../services/context/ApiContext";

const PrintButton = () => {
  const { dadosUsers, dadosArtes } = useApi();

  const handlePrint = async () => {
    try {
      // Criar um blob de dados
      const blob = new Blob(
        [JSON.stringify({ dadosUsers, AQUICOMECAARTES: dadosArtes })],
        {
          type: "application/json",
        }
      );

      // Criar um URL local para o blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Abrir uma nova janela ou aba contendo o conteúdo do arquivo
      const newWindow = window.open(blobUrl, "_blank");

      // Aguardar um pequeno intervalo de tempo para garantir que a janela ou aba tenha sido aberta
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Imprimir a página recém-aberta
      newWindow.print();
    } catch (error) {
      console.error("Erro ao imprimir dados:", error);
    }
  };

  return (
    <button onClick={handlePrint}>Imprimir Dados do Banco de Dados</button>
  );
};

export default PrintButton;
