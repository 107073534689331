import React, { useState, useEffect } from "react";
import "../styles/CookiePopup.css";

const CookiePopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = (): void => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };
  const handleClose = (): void => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-popup">
      <p>
        Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a
        nossa
        <a
          href="/politica-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
        e, ao continuar navegando, você concorda com estas condições.
      </p>
      <div className="btns-div">
        <button className="accept-btn" onClick={handleAccept}>
          Concordo
        </button>
        <button className="close-btn" onClick={handleClose}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default CookiePopup;
