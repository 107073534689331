// AppRouter.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/inicialPages/Home";
import Sobre from "./pages/inicialPages/About";
import ProfilePage from "./components/ProfileComponent";
import ArtPage from "./components/ArtPageComponent";
import Login from "./pages/inicialPages/Login";
import AdmUser from "./pages/admPages/AdmUser";
import ProfileAdmin from "./pages/admPages/ProfileAdmin";
import ArtistAdmin from "./pages/admPages/ArtistAdmin";
import ArtAdmin from "./pages/admPages/ArtAdmin";
import ProfileAdminPost from "./pages/admPages/ProfileAdminPost";
import ProfilePageEdit from "./components/ProfilePageComponent";
import Artist from "./pages/inicialPages/Artist";
import ArtPageEdit from "./pages/admPages/ArtPagePostAdmin";
import ProfileArtist from "./pages/artistPages/ProfileArtist";
import AdmArtist from "./pages/artistPages/AdmArtist";
import ProfileArtistEdit from "./pages/artistPages/ProfileArtistEdit";
import ProfileArtistPost from "./pages/artistPages/ProfileArtistPost";
import ProfileArtistArtEdit from "./pages/artistPages/ProfileArtistArtEdit";
import ArtList from "./pages/inicialPages/Art";
import ArtistProfileComponent from "./components/ArtistProfileComponent";
import JunteSe from "./pages/inicialPages/juntese";
import PrintButton from "./components/data";
import PrivateRoute from "./components/PrivateRoute";
import Privacy from "./pages/inicialPages/Privacy";
import Ia from "./pages/inicialPages/Ia";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/artistas" element={<Artist />} />
        <Route path="/artes" element={<ArtList />} />
        <Route path="/juntese" element={<JunteSe />} />
        <Route path="/login" element={<Login />} />
        <Route path="/politica-de-privacidade" element={<Privacy />} />

        <Route path="/ia" element={<Ia />} />

        <Route path="/arte/:arteId" element={<ArtPage />} />
        <Route path="/profile/:id" element={<ProfilePage />} />

        <Route element={<PrivateRoute />}>
          <Route path="/profileartist/:id" element={<ProfileArtist />} />
          <Route path="profile/:id/arte/:arteId" element={<ArtPage />} />
          <Route path="/profile/:id/artist" element={<ProfilePage />} />

          {/* Adicionando as rotas do in */}
          <Route path="/in/:id" element={<Home />} />
          <Route path="/sobre/in/:id" element={<Sobre />} />
          <Route path="/artes/in/:id" element={<ArtList />} />
          <Route path="/artistas/in/:id" element={<Artist />} />
          <Route path="/profile/in/:id" element={<ProfilePage />} />
          <Route path="/profile/in/:id/:artistId" element={<ProfilePage />} />

          {/* Adicionando as rotas do adm */}
          <Route path="/profile/:id/adm" element={<ProfilePage />} />
          <Route path="/profile/:id/adm/BATATA007" element={<PrintButton />} />
          <Route path="/profile/:id/adm/:userId/" element={<ProfilePage />} />

          {/* Adicionando as rotas do admuser */}
          <Route path="/admuser/:id" element={<AdmUser />} />
          <Route path="/admuser/:id/perfil" element={<ProfileAdmin />} />
          <Route path="/admuser/:id/artistas" element={<ArtistAdmin />} />
          <Route path="/admuser/:id/artes" element={<ArtAdmin />} />
          <Route
            path="/admuser/:id/profilepost"
            element={<ProfileAdminPost />}
          />
          <Route
            path="/admuser/:id/admuser/:userId/perfiladm"
            element={<ProfilePageEdit />}
          />
          <Route path="/admuser/:id/artepost" element={<ArtPageEdit />} />
          <Route
            path="/admuser/:id/arte/:arteId/admuser"
            element={<ArtPage />}
          />
          <Route
            path="/admuser/:id/artistas/admuser/:userId/perfilartistaedit"
            element={<ProfileAdmin />}
          />
          <Route
            path="/admuser/:id/artistprofile/:id"
            element={<ArtistProfileComponent />}
          />

          {/* Adicionando as rotas do admartist */}
          <Route path="/admartist/:id" element={<AdmArtist />} />
          <Route
            path="/admartist/:id/profile"
            element={<ProfileArtistEdit />}
          />
          <Route
            path="/admartist/:id/artepost"
            element={<ProfileArtistPost />}
          />
          <Route
            path="/admartist/:id/arteedit"
            element={<ProfileArtistArtEdit />}
          />
          <Route
            path="admartist/:id/arte/:arteId/admartist"
            element={<ArtPage />}
          />
          <Route
            path="admartist/:id/arte/:arteId/login"
            element={<ArtPage />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
