import React, { useState, ChangeEvent, useEffect } from "react";
import { useApi } from "../../services/context/ApiContext";
import Footer from "../../components/FooterComponent";
import HeaderAdmin from "../../components/HeaderAdmin";
import Header from "../../components/HeaderComponent";
import Loading from "../../components/LoadingComponent";
import "../../styles/ArtAdmin.css";
import Popup from "../../components/PopUpComponent";
import { useParams } from "react-router-dom";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "../../components/BtnComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/Galeria.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../../utils/Crypto";

interface Arte {
  _id: string;
  foto: string;
  nome_artista: string;
  nome_livro: string;
  username: string;
  descricao: string;
  livro: string;
}
interface GaleriaItem {
  _id: any;
  foto: string;
  nome_artista: string;
  nome: string;
  endereco: string;
}

const ArtAdmin: React.FC = () => {
  const {
    fazerLogin,
    dadosArtes,
    enviarDadosParaBackendArt,
    dadosUsers,
    deleteArte,
  } = useApi();
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("nome_livro"); // Campo padrão para busca
  const [selectedArte, setSelectedArte] = useState<Arte | null>(null);
  const [novaCapaLivro, setNovaCapaLivro] = useState<File | null>(null);
  const [larguraTotal, setLarguraTotal] = useState(100);
  const [isLoading, setIsLoading] = useState(true);

  const [showPopup, setShowPopup] = useState(false);

  const [novoNomeLivro, setNovoNomeLivro] = useState<string>("");
  const [originalNomeLivro, setOriginalNomeLivro] = useState<string>("");
  const [isEditingName, setIsEditingName] = useState(false);

  const [novaDescricao, setNovaDescricao] = useState<string>("");
  const [originalDescricao, setOriginalDescricao] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);

  const [novoEscritor, setNovoEscritor] = useState<string>("");
  const [originalEscritor, setOriginalEscritor] = useState<string>("");
  const [isEditingArtist, setIsEditingArtist] = useState(false);

  const [novoLivro, setNovoLivro] = useState<string>("");
  const [originalLivro, setOriginalLivro] = useState<string>("");
  const [isEditingState, setIsEditingState] = useState(false);

  const [novoUsername, setNovoUsername] = useState<string>("");
  const [originalUsername, setOriginalUsername] = useState<string>("");
  const [isEditingAdress, setIsEditingAdress] = useState(false);

  const [isEditingToken, setIsEditingToken] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const [newId, setNewId] = useState();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const updateArte = async () => {
    const { accessToken, refreshToken, notOk } = await fazerLogin({
      email,
      senha,
    });

    try {
      const dados = {
        novaCapaLivro,
        novoNomeLivro,
        novaDescricao,
        novoUsername,
        novoEscritor,
        novoLivro,
        id: newId,
        accessToken,
      };
      setIsEditingToken(!isEditingToken);
      setShowPopup(true);
      return await enviarDadosParaBackendArt(dados);
    } catch (error) {
      console.error("Error during user deletion:", error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const { accessToken, refreshToken, notOk } = await fazerLogin({
        email,
        senha,
      });

      if (notOk) {
        confirmAlert({
          title: "Aviso",
          message: "Por favor, forneça seu email e senha válidos.",
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{"Aviso"}</h1>
                <p>
                  {
                    "Por favor, forneça seu email e senha válidos para confirmar a exclusão da Arte."
                  }
                </p>
                <button className="custom-ui-btn" onClick={onClose}>
                  OK
                </button>
              </div>
            );
          },
        });
        return;
      }

      if (!newId) {
        confirmAlert({
          title: "Aviso",
          message: "Por favor, escolha uma Arte antes de excluir.",
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1>{"Aviso"}</h1>
                <p>{"Por favor, escolha uma Arte antes de excluir."}</p>
                <button className="custom-ui-btn" onClick={onClose}>
                  OK
                </button>
              </div>
            );
          },
        });
        return;
      }

      confirmAlert({
        title: "Confirmação",
        message: "Tem certeza que deseja deletar essa Arte?",
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{"Confirmação"}</h1>
              <p>{"Tem certeza que deseja deletar essa Arte?"}</p>
              <button
                className="custom-ui-btn"
                onClick={() => {
                  updateArte();
                  onClose();
                }}
              >
                Sim
              </button>
              <button
                className="custom-ui-btn"
                onClick={() => {
                  onClose();
                }}
              >
                Não
              </button>
            </div>
          );
        },
      });
    } catch (error) {
      console.error("Erro durante o login:", error);
    }
  };

  const toggleEditModeAdress = () => {
    if (isEditingAdress) {
      setOriginalUsername(novoUsername);
    } else {
      setNovoUsername(originalUsername);
    }
    setIsEditingAdress(!isEditingAdress);
  };

  const toggleEditModeState = () => {
    if (isEditingState) {
      setOriginalLivro(novoLivro);
    } else {
      setNovoLivro(originalLivro);
    }
    setIsEditingState(!isEditingState);
  };

  const toggleEditModeArtist = () => {
    if (isEditingArtist) {
      setOriginalEscritor(novoEscritor);
    } else {
      setNovoEscritor(originalEscritor);
    }
    setIsEditingArtist(!isEditingArtist);
  };

  const toggleEditModeName = () => {
    if (isEditingName) {
      setOriginalNomeLivro(novoNomeLivro);
    } else {
      setNovoNomeLivro(originalNomeLivro);
    }
    setIsEditingName(!isEditingName);
  };

  const toggleEditMode = () => {
    if (isEditing) {
      setOriginalDescricao(novaDescricao);
    } else {
      setNovaDescricao(originalDescricao);
    }
    setIsEditing(!isEditing);
  };

  const isAdmin = async ({ email, senha }: any) => {
    try {
      const { accessToken, refreshToken } = await fazerLogin({ email, senha });
      if (accessToken && refreshToken) {
        return true;
      }
    } catch (error) {
      console.error("Erro durante o login:", error);
    }
    return false;
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFieldChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSearchField(event.target.value);
  };

  const handleArteClick = (arteId: number) => {
    if (arteId) {
      setNewId(arteId as any);

      const clickedArte = dadosArtes.find((arte) => arte._id === arteId);
      setSelectedArte(clickedArte || null);

      console.log(arteId);
    }
  };

  const filteredArtes =
    dadosArtes &&
    dadosArtes.filter((arte) => {
      if (arte !== undefined && arte[searchField] !== undefined) {
        return arte[searchField]
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      }
      return false;
    });

  useEffect(() => {
    if (id) {
      const foundUser = dadosUsers.find((u) => u._id === decrypt(id));

      if (foundUser) {
        const emailStorage: string = foundUser.email;
        setEmail(emailStorage);
      } else {
        console.error("Usuário não encontrado");
      }
    }
    if (selectedArte) {
      setOriginalNomeLivro(selectedArte.nome_livro);
      setNovoNomeLivro(selectedArte.nome_livro);

      setOriginalDescricao(selectedArte.descricao);
      setNovaDescricao(selectedArte.descricao);

      setOriginalEscritor(selectedArte.nome_artista);
      setNovoEscritor(selectedArte.nome_artista);

      setOriginalLivro(selectedArte.livro);
      setNovoLivro(selectedArte.livro);

      setOriginalUsername(selectedArte.username);
      setNovoUsername(selectedArte.username);
    } else {
      console.error("Arte não encontrada ");
    }
  }, [newId, id, selectedArte]);

  useEffect(() => {
    const handleResize = async () => {
      const numeroDeImgs = window.innerWidth / 300;

      const numeroTotal =
        +numeroDeImgs.toFixed(0) < filteredArtes.length
          ? numeroDeImgs
          : filteredArtes.length - 1;

      const resulNumber = +numeroTotal === 0 ? 1 : +numeroTotal;
      setLarguraTotal(+resulNumber.toFixed(0));
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filteredArtes.length]);

  const showDeleteConfirmation = async () => {
    const { accessToken, refreshToken, notOk } = await fazerLogin({
      email,
      senha,
    });

    if (notOk) {
      confirmAlert({
        title: "Aviso",
        message: "Por favor, forneça seu email e senha válidos.",
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{"Aviso"}</h1>
              <p>
                {
                  "Por favor, forneça seu email e senha válidos para confirmar a exclusão da Arte."
                }
              </p>
              <button className="custom-ui-btn" onClick={onClose}>
                OK
              </button>
            </div>
          );
        },
      });
      return;
    }

    if (!newId) {
      confirmAlert({
        title: "Aviso",
        message: "Por favor, escolha uma Arte antes de excluir.",
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1>{"Aviso"}</h1>
              <p>{"Por favor, escolha uma Arte antes de excluir."}</p>
              <button className="custom-ui-btn" onClick={onClose}>
                OK
              </button>
            </div>
          );
        },
      });
      return;
    }

    confirmAlert({
      title: "Confirmação",
      message: "Tem certeza que deseja deletar essa Arte?",
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>{"Confirmação"}</h1>
            <p>{"Tem certeza que deseja deletar essa Arte?"}</p>
            <button
              className="custom-ui-btn"
              onClick={() => {
                try {
                  const dados = {
                    token: accessToken,
                    id: newId,
                  };
                  deleteArte(dados);
                  const result = deleteArte(dados);
                  if (result && result.includes("removida com sucesso")) {
                    console.log("deletado");
                  }

                  navigate(`/admuser/${id}/artes`);
                } catch (error) {
                  console.error("Error during user deletion:", error);
                }
                onClose();
              }}
            >
              Sim
            </button>
            <button
              className="custom-ui-btn"
              onClick={() => {
                onClose();
              }}
            >
              Não
            </button>
          </div>
        );
      },
    });
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: larguraTotal,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  if (!isAdmin) {
    return (
      <>
        <Header />
        <div className="content">
          <p>Você não tem permissão para acessar esta página.</p>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <HeaderAdmin />
      <div className="art-admin-container">
        {isLoading && <Loading />}
        {dadosArtes.length <= 0 && <Loading />}
        <div className="container-home-admin">
          <div className="input-container-adm">
            <input
              type="text"
              className="inputField-adm"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <img
              src="/lupa.png"
              alt="Ícone de Lupa"
              className="search-icon-adm"
            />
            <select
              value={searchField}
              onChange={handleSearchFieldChange}
              className="selectField-adm"
            >
              <option value="nome_livro">Nome da Arte</option>
              <option value="nome_artista">Escritor</option>
            </select>
          </div>

          <p className="title-maps">Clique na Arte para Editar</p>
          <Slider {...settings} className="galeria">
            {filteredArtes.map((item: GaleriaItem) => (
              <div
                key={item._id}
                className="galeria-item"
                onClick={() => handleArteClick(item._id)}
              >
                <img
                  src={item.foto}
                  className="imagem-galeria"
                  alt={`Arte de ${item.nome_artista}`}
                />
                <p className="nome-trabalho">{item.nome}</p>
                <p className="nome-artista">{item.nome_artista}</p>
                <p className="nome-trabalho">{item.endereco}</p>
              </div>
            ))}
          </Slider>

          <div className="art-container-page">
            {selectedArte && (
              <div>
                <div className="user-info-adm">
                  {isEditingName ? (
                    <input
                      type="text"
                      name="nome_livro"
                      value={novoNomeLivro}
                      onChange={(e) => setNovoNomeLivro(e.target.value)}
                      className="username-input"
                      placeholder="Nome do Livro"
                    />
                  ) : (
                    <p className="p-instagram">{originalNomeLivro}</p>
                  )}
                  <button
                    onClick={toggleEditModeName}
                    className="email-edit-button"
                  >
                    {isEditingName ? "Salvar" : "Editar Nome do Livro"}
                  </button>
                </div>
                <label className="label-cover">
                  <input
                    type="file"
                    name="imagem"
                    accept="image/*"
                    onChange={(e) =>
                      setNovaCapaLivro(e.target.files?.[0] || null)
                    }
                    className="cover-input"
                  />
                  <img
                    src={selectedArte.foto}
                    alt={`Capa de ${selectedArte.nome_livro}`}
                    className="art-photo"
                  />
                </label>
                {isEditing ? (
                  <div>
                    Descrição
                    <textarea
                      rows={1}
                      name="descricao"
                      value={novaDescricao}
                      onChange={(e) => setNovaDescricao(e.target.value)}
                      className="username-input"
                    />
                  </div>
                ) : (
                  <p className="p-instagram">{originalDescricao}</p>
                )}
                <button onClick={toggleEditMode} className="edit-button">
                  {isEditing ? "Salvar" : "Editar Descrição"}
                </button>
                <div className="user-info-adm">
                  {isEditingArtist ? (
                    <div>
                      <input
                        type="text"
                        name="nome_artista"
                        value={novoEscritor}
                        onChange={(e) => setNovoEscritor(e.target.value)}
                        className="username-input"
                      />
                    </div>
                  ) : (
                    <p className="p-instagram">
                      {" "}
                      Escritor(s): {originalEscritor}
                    </p>
                  )}
                  <button
                    onClick={toggleEditModeArtist}
                    className="email-edit-button"
                  >
                    {isEditingArtist ? "Salvar" : "Editar Descrição"}
                  </button>
                </div>

                <div className="user-info-adm">
                  {isEditingAdress ? (
                    <div>
                      <input
                        type="text"
                        name="username"
                        value={novoUsername}
                        onChange={(e) => setNovoUsername(e.target.value)}
                        className="username-input"
                      />
                    </div>
                  ) : (
                    <p className="p-instagram"> username: {originalUsername}</p>
                  )}
                  <button
                    onClick={toggleEditModeAdress}
                    className="email-edit-button"
                  >
                    {isEditingAdress ? "Salvar" : "Editar username"}
                  </button>
                </div>

                <div className="user-info-adm">
                  {isEditingState ? (
                    <div>
                      <input
                        name="pdf"
                        value={novoLivro}
                        onChange={(e) => setNovoLivro(e.target.value)}
                        className="username-input"
                      />
                    </div>
                  ) : (
                    <p className="p-instagram">
                      pdf:
                      <br />
                      {}
                    </p>
                  )}
                  <button
                    onClick={toggleEditModeState}
                    className="email-edit-button"
                  >
                    {isEditingState ? "Salvar" : "Editar Livro"}
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="form-update-post">
            <p className="form-update">Digite sua Senha para continuar...</p>

            <p className="email-input">Email:</p>
            <input
              type="email"
              name="email-TOKEN"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="username-input"
              placeholder="email"
            />

            <p>Senha:</p>
            <input
              type="password"
              name="senha-TOKEN"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              className="username-input"
              placeholder="senha"
            />

            <button onClick={handleSaveChanges} className="edit-button-finish">
              Atualizar os Dados
            </button>

            {showPopup && (
              <Popup
                message="Dados Atualizados com Sucesso"
                onClose={closePopup}
              />
            )}
          </div>
          <button
            onClick={showDeleteConfirmation}
            className="delete-button"
            // disabled={isDeleteButtonDisabled}
          >
            Deletar Arte
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtAdmin;
