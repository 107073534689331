// PrivateRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const PrivateRoute: React.FC = () => {
  const checkAuthentication = () => {
    try {
      const accessTokenCookie = Cookies.get("jwtToken");
      const refreshTokenCookie = Cookies.get("refreshToken");

      if (accessTokenCookie) {
        const decodedAccessToken = jwtDecode(accessTokenCookie) as any;
        const currentTime = Date.now() / 1000;

        if (decodedAccessToken.exp > currentTime) {
          return true;
        }
      }

      if (refreshTokenCookie) {
        const decodedRefreshToken = jwtDecode(refreshTokenCookie) as any;
        const currentTime = Date.now() / 1000;

        if (decodedRefreshToken.exp > currentTime) {
          // Opcional: Tentar renovar o access token usando o refresh token
          return true;
        }
      }

      return false;
    } catch (error) {
      console.error("Erro ao verificar autenticação:", error);
      return false;
    }
  };

  const isAuthenticated = checkAuthentication();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
