import React, { useState, useEffect, useMemo, ChangeEvent } from "react";
import { useApi } from "../../services/context/ApiContext";
import Footer from "../../components/FooterComponent";
import "../../styles/writerList.css";
import Header from "../../components/HeaderComponent";
import Loading from "../../components/LoadingComponent";
import HeaderLoginComponent from "../../components/HeaderLoginComponent";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../../utils/Crypto";
import logored from "../../assets/logored.png";

interface Writer {
  _id: string;
  name: string;
  foto_perfil: string;
  [key: string]: any;
}

const ArtistAdmin: React.FC = () => {
  const { fazerLogin, dadosUsers } = useApi();

  const [paginaAtual, setPaginaAtual] = useState(1);
  const [artesExibidas, setArtesExibidas] = useState<Writer[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("name");
  const navigate = useNavigate();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchFieldChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSearchField(event.target.value);
  };

  const filteredWriter = useMemo(() => {
    return (
      dadosUsers &&
      dadosUsers.filter((writer: Writer) => {
        const searchTermWithoutTilde = searchTerm.replace(/~/g, "");
        const fieldValue = writer && writer[searchField];

        if (fieldValue) {
          const fieldValueWithoutTilde = fieldValue
            .toUpperCase()
            .replace(/~/g, "");

          return (
            fieldValueWithoutTilde.includes(
              searchTermWithoutTilde.toUpperCase()
            ) ||
            (searchTermWithoutTilde.startsWith("~") &&
              fieldValueWithoutTilde.includes(
                searchTermWithoutTilde.substring(1).toUpperCase()
              ))
          );
        }
        return false;
      })
    );
  }, [dadosUsers, searchTerm, searchField]);

  useEffect(() => {
    if (dadosUsers.length > 0) {
      const inicio = (paginaAtual - 1) * 12;
      const fim = Math.min(inicio + 12, dadosUsers.length);
      setArtesExibidas(filteredWriter.slice(inicio, fim));
    }
  }, [dadosUsers, paginaAtual, filteredWriter]);

  const handleProximaPagina = () => {
    setPaginaAtual((prevPagina) => prevPagina + 1);
  };

  const handlePaginaAnterior = () => {
    setPaginaAtual((prevPagina) => Math.max(prevPagina - 1, 1));
  };

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  const handleArteClick = (arteId: string) => {
    const clickedArte = dadosUsers.find((arte: Writer) => arte._id === arteId);
    navigate(`/profile/${encrypt(arteId)}`);
  };

  const redirecionarParaJuntese = () => {
    navigate("/juntese");
  };

  return (
    <>
      {headerOrHeaderLogin()}
      {dadosUsers.length <= 0 ? (
        <Loading />
      ) : (
        <section className="art-main-writer">
          <p className="user-list-header-artist">Conheça os Escritores </p>
          <div className="input-container-adm-writer">
            <input
              type="text"
              className="inputField-adm"
              placeholder={`🔍  Pesquisar por Escritor... `}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="card-grid-writer">
            {!artesExibidas ||
              (artesExibidas.length === 0 && (
                <p className="galeria-item">
                  Nenhum(a) Escritor(a) encontrado(a)
                </p>
              ))}
            {artesExibidas.map((item) => (
              <div
                className="card-writer"
                key={item._id}
                onClick={() => handleArteClick(item._id)}
              >
                <img
                  src={item.foto_perfil}
                  alt={`Capa de ${item.name}`}
                  className="art-photo-writer"
                />
                <div>
                  <p className="description-p-art-writer">
                    {item.name}{" "}
                    <img src={logored} alt="" className="logo-red" />
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination-writer">
            <button
              className="btn-next-writer"
              onClick={handlePaginaAnterior}
              disabled={paginaAtual === 1}
            >
              Anterior
            </button>
            <span className="pagina-numero-writer">Página {paginaAtual}</span>
            <button
              className="btn-next-writer"
              onClick={handleProximaPagina}
              disabled={artesExibidas.length < 8}
            >
              Próxima
            </button>
          </div>
          <section className="sobre-section-writer">
            <div className="text-sobre-writer">
              <p className="titulo-sobre">Seja um Autor(a) do 'Horror Story'</p>
              <p className="intro-text">
                Junte-se a nós e publique suas histórias! Entre em nossa
                comunidade aterrorizante e compartilhe seus contos sombrios
                conosco. Se suas histórias forem aprovadas, elas serão
                publicadas em nosso site, proporcionando uma plataforma para
                você alcançar outros amantes do terror. Não perca essa
                oportunidade de se juntar à nossa comunidade e deixar sua marca
                no mundo do horror!
              </p>
            </div>
            <button
              className="sobre-btn-faca"
              onClick={redirecionarParaJuntese}
            >
              Seja um Autor(a)
            </button>
          </section>
        </section>
      )}
      <Footer />
    </>
  );
};

export default ArtistAdmin;
