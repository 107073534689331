import React, { useState, useEffect, useRef } from "react";
import "../styles/HeaderAdmin.css";
import logo from "../assets/logocomnome1.webp";
import { useNavigate, useParams } from "react-router-dom";
import { decrypt } from "../utils/Crypto";
import Cookies from "js-cookie";

const HeaderAdmin: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = (event: MouseEvent) => {
    if (menuRef.current && buttonRef.current) {
      if (
        !menuRef.current.contains(event.target as Node) &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", closeMenu);
    } else {
      document.removeEventListener("mousedown", closeMenu);
    }

    return () => {
      document.removeEventListener("mousedown", closeMenu);
    };
  }, [isMenuOpen]);

  const handleLogout = () => {
    Cookies.remove("jwtToken");
    Cookies.remove("refreshToken");
    navigate("/", { replace: true });
  };

  return (
    <div className="header-admin">
      <div className="menu-button-admin" ref={buttonRef} onClick={toggleMenu}>
        ☰
      </div>
      <section className="head-1-admin">
        <h1 className="titulo-esquerda-admin">
          <img className="logo-admin" src={logo} alt="Logo Instinto Urbano" />
          <a
            href={`/in/${decrypt(id as string)}`}
            className="link-sem-sublinhado-admin"
          >
            Horror Story
          </a>
        </h1>
        <nav ref={menuRef} className={isMenuOpen ? "open" : ""}>
          <ul className="lista-direita-admin">
            <li className="li-header">
              <a href={`/admuser/${id}`}>Início</a>
            </li>
            <li className="li-header">
              <a href={`/admuser/${id}/perfil`}>Meu Perfil</a>
            </li>
            <li className="li-header">
              <a href={`/admuser/${id}/artistas`}>Escritores</a>
            </li>
            <li className="li-header">
              <a href={`/admuser/${id}/artes`}>Editar/deletar</a>
            </li>
            <li className="li-header">
              <a href={`/admuser/${id}/profilepost`}>Novo Escritor</a>
            </li>
            <li className="li-header">
              <a href={`/admuser/${id}/artepost`}>Nova História</a>
            </li>
            <li className="li-header">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
              >
                Sair
              </a>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
};

export default HeaderAdmin;
