import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApi } from "../services/context/ApiContext";
import "../styles/Profile.css";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import Loading from "./LoadingComponent";
import InstagramLogo from "../assets/instagram.png";
import LinkedInLogo from "../assets/linkedin.png";
import EmailLogo from "../assets/email.png";
import logo from "../assets/logo-cat.webp";
import logo2 from "../assets/logo--cat-2.webp";
import HeaderAdmin from "./HeaderAdmin";
import HeaderArtist from "./HeaderArtist";
import Slider from "react-slick";
import { CustomNextArrow, CustomPrevArrow } from "./BtnComponent";
import HeaderLoginComponent from "./HeaderLoginComponent";
import { decrypt, encrypt } from "../utils/Crypto";

interface User {
  _id: number;
  username: string;
  foto_capa: string;
  foto_perfil: string;
  descricao_perfil: string;
  email: string;
  linkedin: string;
  instagram: string;
  name: string;
  adminstrador: string;
  descricao_curta: string;
  administrador: boolean;
}
interface Arte {
  _id: string;
  foto: string;
  nome_artista: string;
  nome: string;
  endereco: string;
  nome_livro: string;
}

interface GaleriaItem extends Arte {}

const ProfilePage: React.FC = () => {
  const { id, userId, artistId } = useParams<{
    id?: string;
    userId?: string;
    artistId?: string;
  }>();
  const [user, setUser] = useState<User | null>(null);
  const [larguraTotal, setLarguraTotal] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedArte, setSelectedArte] = useState<Arte | null>(null);

  const { dadosUsers, dadosArtes } = useApi();

  useEffect(() => {
    // console.log("006", decrypt(id as any));
    let foundUser;

    // Verifica se o ID não é nulo e não inclui ':'
    if (id && !id.includes(":")) {
      if (userId) {
        foundUser = dadosUsers.find((u) => u._id === userId);
      } else {
        foundUser = dadosUsers.find((u) => u._id === id);
      }

      if (foundUser) {
        setUser(foundUser);
      } else {
        // console.error("Usuário não encontrado");
      }
    }

    const edId = id;
    if (edId) {
      if (userId) {
        foundUser = dadosUsers.find((u) => u._id === userId);
      } else {
        foundUser = dadosUsers.find((u) => u._id === decrypt(edId));
      }

      if (foundUser) {
        setUser(foundUser);
      } else {
        // console.error("Usuário não encontrado");
      }
    }
  }, [id, userId, dadosUsers]);

  const userArtist = dadosUsers.find((user) => user._id === decrypt(id as any));

  const headerOrHeaderAdm = () => {
    const urlAtual = window.location.href;
    // console.log("006", artistId);
    if (!userArtist.administrador && urlAtual.includes("in")) {
      return <HeaderLoginComponent />;
    }
    if (userArtist.administrador && urlAtual.includes("in")) {
      return <HeaderLoginComponent />;
    }

    if (urlAtual.includes(`artist`)) {
      return <HeaderArtist />;
    }
    if (urlAtual.includes(`adm`)) {
      return <HeaderAdmin />;
    } else {
      return <Header />;
    }
  };
  // Render
  const userData =
    dadosUsers.find((u) => u._id === decrypt(id as string)) || null;
  const filteredArtes = dadosArtes.filter((arte) =>
    arte.username
      ?.toLocaleUpperCase()
      .includes(userData?.username.toLocaleUpperCase())
  );
  // console.log(filteredArtes);

  const admUser = userData;

  // Effects
  useEffect(() => {
    const larguraDaTela = window.innerWidth;
    const handleResize = () => {
      const larguraOriginalDaImagem = 150;
      const numeroMaximoDeImagens = 5;

      const quantidadeDeImgsPorTela = larguraDaTela / larguraOriginalDaImagem;

      const numeroTotal = larguraDaTela / filteredArtes.length;

      const numeroDeImgsSemFiltro = Math.min(
        Math.floor(larguraDaTela / larguraOriginalDaImagem),
        numeroMaximoDeImagens
      );

      const numero = Math.min(
        Math.floor(larguraDaTela / +numeroTotal.toFixed(0)),
        numeroMaximoDeImagens
      );

      const realNumero =
        filteredArtes.length < quantidadeDeImgsPorTela
          ? numero
          : numeroDeImgsSemFiltro;

      setLarguraTotal(realNumero);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [filteredArtes.length, window.innerWidth]);

  // Other Functions
  const handleArteClick = (arteId: string) => {
    const clickedArte = dadosArtes.find((arte) => arte._id === arteId);
    setSelectedArte(clickedArte || null);
    const urlAtual = window.location.href;
    if (urlAtual.includes("admartist")) {
      navigate(`/admartist/${artistId}/arte/${arteId}/login`);
    } else {
      navigate(`/arte/${encrypt(arteId)}`);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: larguraTotal,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const [loadingSrc, setLoadingSrc] = useState(logo); // define a primeira imagem como padrão

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc: any) => (prevSrc === logo ? logo2 : logo)); // alterna entre as imagens
    }, 275); // alterna a cada segundo

    return () => clearInterval(interval);
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div>
      {headerOrHeaderAdm()}
      <div className="profile-container">
        {user ? (
          <div className="div-perfil">
            {user.foto_capa ? (
              <img
                src={user.foto_capa}
                alt={`Capa de ${user.name}`}
                className="cover-photo"
              />
            ) : (
              <img
                src={logo}
                style={{
                  width: "350px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                  filter: "blur(10px)",
                }}
                alt={`Capa de ${user.name}`}
                className="cover-photo"
              />
            )}

            <div className="description-data">
              {user.foto_perfil ? (
                <img
                  src={user.foto_perfil}
                  alt={`Foto de perfil de ${user.name}`}
                  className="profile-photo"
                />
              ) : (
                <img
                  src={logo}
                  alt={`Capa de ${user.name}`}
                  className="blur-effect"
                />
              )}
            </div>
            <div className="user-info">
              <div className="social-links">
                {user.administrador === true && (
                  <a href={`mailto:${user.email}`}>
                    <img
                      src={EmailLogo}
                      alt="E-mail"
                      className="social-logo-profile"
                    />
                  </a>
                )}
                {user.linkedin !== "nt" && (
                  <a
                    href={user.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LinkedInLogo}
                      alt="LinkedIn"
                      className="social-logo-profile"
                    />
                  </a>
                )}
                {user.instagram !== "nt" && (
                  <a
                    href={user.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={InstagramLogo}
                      alt="Instagram"
                      className="social-logo-profile"
                    />
                  </a>
                )}
              </div>
              <p>{user.name}</p>
            </div>
            <div className="description-p">
              <p>{user.descricao_perfil}</p>
              <img
                className="logo-profile"
                src={logo}
                alt="descrição do perfil do escritor"
              />
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
      <section
        style={{
          maxWidth: "1200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        {admUser && (
          <p className="title-profile-artist">Livros de {admUser.name}</p>
        )}
        {/* <section className="container-carrossel-perfil"> */}
        {admUser && (
          <Slider {...settings} className="galeria">
            {filteredArtes.map((item: GaleriaItem) => (
              <div
                key={item._id}
                className="galeria-item"
                onClick={() => handleArteClick(item._id)}
              >
                <p className="nome-user-artist">{item.nome_livro}</p>
                <img
                  src={item.foto}
                  className="imagem-galeria"
                  alt={`Arte de ${item.nome_artista}`}
                />
              </div>
            ))}
          </Slider>
        )}
        {/* </section> */}
      </section>

      <Footer />
    </div>
  );
};

export default ProfilePage;
