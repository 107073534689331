import React from "react";

import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import "../../styles/About.css";
import Galeria from "../../components/GaleriaComponent";
import UserList from "../../components/UsersComponent";
import { useApi } from "../../services/context/ApiContext";
import Loading from "../../components/LoadingComponent";

import logo from "../../assets/logocomnome1.webp";
import HeaderLoginComponent from "../../components/HeaderLoginComponent";

const JunteSe: React.FC = () => {
  const { dadosUsers } = useApi();

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  return (
    <>
      {headerOrHeaderLogin()}
      {dadosUsers && dadosUsers.length > 0 ? (
        <div>
          <section className="about-section">
            <p className="about-h2"> Horror Story</p>
            <p className="about-p">
              Para fazer parte da nossa comunidade, envie um{" "}
              <strong>e-mail</strong> com o <strong>nome</strong> do seu livro e
              um <strong>PDF</strong> da obra. Em <strong>7 dias</strong> ,
              retornaremos com uma resposta e seu livro será{" "}
              <strong>publicado em nosso site</strong> com um{" "}
              <strong>perfil de autor </strong>
              para se juntar à nossa{" "}
              <strong>comunidade de escritores de terror</strong>.
              <strong>
                {" "}
                Não perca tempo, envie sua história e faça parte do nosso
                universo sombrio!
              </strong>
            </p>
            <p className="about-p">
              <strong>
                <i> matheuspradoescritor@gmail.com</i>
              </strong>
            </p>
          </section>
          <div className="container-logo">
            <img src={logo} alt="logo-about" className="logo-about" />
            <UserList />
            <p className="about-h2">Aprecie as Histórias </p>
            <section>
              <Galeria />
            </section>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default JunteSe;
