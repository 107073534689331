import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../services/context/ApiContext";
import "../styles/Users.css";
import { useParams } from "react-router-dom";
import { encrypt } from "../utils/Crypto";

const UserList: React.FC = () => {
  const { dadosUsers, dadosArtes, isOnUsers, isOnBooks } = useApi();
  const navigate = useNavigate();
  const { id, artistId } = useParams<{ id?: string; artistId?: string }>();
  const [user, setUser] = useState<any>([]);
  const [idParams, setIdParams] = useState<string | undefined>(id);
  const [isOnOrOff, setIsOnOrOff] = useState<boolean>(false);

  useEffect(() => {
    if (isOnUsers.length > 0 || isOnBooks.length > 0) {
      setIsOnOrOff(true);
    }
  }, [isOnBooks, isOnUsers]);

  const navigateToProfile = (userId: string) => {
    const findUser = dadosUsers.find((user) => user._id === idParams);

    if (findUser && findUser.administrador) {
      navigate(`/profile/in/${encrypt(userId)}/${id}`);
    } else if (findUser && !findUser.administrador) {
      navigate(`/profile/in/${encrypt(userId)}/${id}`);
    } else {
      return navigate(`/profile/${encrypt(userId)}`);
    }
  };

  useEffect(() => {
    setIdParams(id);
  }, [id]);

  useEffect(() => {
    const admUsers =
      dadosUsers && dadosUsers.filter((user) => user.administrador === true);

    if (admUsers && !arraysEqual(admUsers, user)) {
      setUser(admUsers);
    }
  }, [dadosUsers, user]);

  function arraysEqual(arr1: Array<any>, arr2: Array<any>) {
    if (arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      for (let key in obj1) {
        if (obj1[key] !== obj2[key]) return false;
      }
    }

    return true;
  }

  return (
    <div className="user-list-container">
      <p className="user-list-header">Equipe de Criação</p>
      <div className="user-grid">
        {user &&
          user.map((user: any) => (
            <div
              key={user._id}
              className="user-item clicavel"
              onClick={() => navigateToProfile(user._id)}
            >
              <img
                src={user.foto_perfil}
                alt={user.username}
                className="user-avatar"
              />
              <span className="nome-user">
                {user.name} <p className={isOnOrOff ? `on` : `off`}></p>
              </span>
              <span>• {user.descricao_curta}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default UserList;
