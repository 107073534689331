import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/HeaderComponent";
import Footer from "../../components/FooterComponent";
import Galeria from "../../components/GaleriaComponent";
import UserList from "../../components/UsersComponent";

import { useApi } from "../../services/context/ApiContext";
import Loading from "../../components/LoadingComponent";

import "../../styles/Home.css";
import ArtistList from "../../components/ArtistListComponent";
import HeaderLoginComponent from "../../components/HeaderLoginComponent";

const Home: React.FC = () => {
  const { dadosArtes } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const coloPage = localStorage.getItem(`colorpage`);
    if (!coloPage) {
      localStorage.setItem(`colorpage`, "yellow");
    }
  }, []);

  const redirecionarParaSobre = () => {
    navigate("/sobre");
  };

  const redirecionarParaJuntese = () => {
    navigate("/juntese");
  };

  const redirecionarParaIa = () => {
    navigate("/ia");
  };

  const headerOrHeaderLogin = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`in`)) {
      return <HeaderLoginComponent />;
    }
    return <Header />;
  };

  return (
    <>
      {headerOrHeaderLogin()}
      {dadosArtes && dadosArtes.length > 0 ? (
        <>
          <div className="main-container">
            <div className="container-home">
              <p className="user-list-header-artist">
                Sua comunidade de Histórias de Terror.
              </p>
            </div>
            <section className="galeria-home">
              <Galeria />
            </section>

            <section className="section-middle">
              <div className="about-sec">
                <section className="sobre-section">
                  <div className="text-sobre">
                    <p className="titulo-sobre">Descubra 'Horror Story'</p>
                    <p className="intro-text">
                      O seu portal para o sobrenatural, o macabro e o
                      desconhecido! Aqui, os corajosos escritores encontram um
                      santuário para dar vida aos seus pesadelos mais
                      arrepiantes, enquanto os leitores se aventuram por entre
                      as sombras em busca de emoções intensas.
                    </p>
                  </div>
                  <button className="sobre-btn" onClick={redirecionarParaSobre}>
                    Sobre nós
                  </button>
                </section>
                <section className="sobre-section">
                  <div className="text-sobre">
                    <p className="titulo-sobre">
                      Seja um Autor(a) do 'Horror Story'
                    </p>
                    <p className="intro-text">
                      Junte-se a nós e publique suas histórias! Entre em nossa
                      comunidade aterrorizante e compartilhe seus contos
                      sombrios conosco. Se suas histórias forem aprovadas, elas
                      serão publicadas em nosso site, proporcionando uma
                      plataforma para você alcançar outros amantes do terror.
                      Não perca essa oportunidade de se juntar à nossa
                      comunidade e deixar sua marca no mundo do horror!
                    </p>
                  </div>
                  <button
                    className="sobre-btn-faca"
                    onClick={redirecionarParaJuntese}
                  >
                    Seja um Autor(a)
                  </button>
                </section>
              </div>
            </section>
          </div>
          <section>
            <ArtistList />
          </section>
          <section className="sobre-section-ia">
            <div className="text-sobre">
              <p className="titulo-sobre">
                <i>Cthulhu</i> Inteligência Artificial do 'Horror Story' para
                Escritores de Terror.
              </p>
              <p className="intro-text">
                Crie aqui suas ideias iniciais, descrições de personagens,
                cenários assustadores ou qualquer detalhe que você deseja
                incorporar em sua história de terror. Cthulhu irá ajudá-lo a
                transformar esses elementos em uma narrativa arrepiante e
                envolvente.
              </p>
            </div>
            <button className="sobre-btn-faca" onClick={redirecionarParaIa}>
              Conheça Cthulhu
            </button>
          </section>
          <section className="users-list-home">
            <UserList />
          </section>
        </>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default Home;
