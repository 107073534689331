// Loading.tsx

import React, { useState, useEffect } from "react";
import "../styles/Loading.css";
import logo from "../assets/logo-cat.webp";
import logo2 from "../assets/logo--cat-2.webp";

const Loading: React.FC = () => {
  const [loadingSrc, setLoadingSrc] = useState(logo);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingSrc((prevSrc: any) => (prevSrc === logo ? logo2 : logo)); // alterna entre as imagens
    }, 275); // alterna a cada segundo

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loading-container">
      <img
        src={loadingSrc}
        alt="Logo"
        style={{ width: "80px", height: "80px" }}
      />
    </div>
  );
};

export default Loading;
