import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useApi } from "../services/context/ApiContext";
import "../styles/ArtPage.css";
import Header from "./HeaderComponent";
import Footer from "./FooterComponent";
import Loading from "./LoadingComponent";
import iconFechar from "../assets/fechar.png";
import HeaderAdmin from "./HeaderAdmin";
import HeaderArtist from "./HeaderArtist";
import HeaderLoginComponent from "./HeaderLoginComponent";
import { decrypt } from "../utils/Crypto";
import Rating from "react-rating-stars-component";
import jsPDF from "jspdf";

interface GaleriaItem {
  _id: string;
  foto: string;
  nome_artista: string;
  nome: string;
  endereco: string;
  descricao: string;
  uf: string;
  cidade: string;
  livro: string;
  nome_livro: string;
  pdf: string;
  rating: number; // Adicionei a propriedade de rating
}

const ArtPage: React.FC = () => {
  const navigate = useNavigate();
  const { arteId } = useParams<{ arteId?: string }>();
  const [art, setArt] = useState<GaleriaItem | null>(null);
  const { dadosArtes } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(5);
  const [avaliations, setavaliations] = useState(1);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const ratingLocalStorage = Number(
      localStorage.getItem(`rating ${decrypt(arteId as any)}`)
    );
    const numberAvaliations = Number(
      localStorage.getItem(`rating-avaliation ${decrypt(arteId as any)}`)
    );
    if (arteId) {
      const foundArt = dadosArtes.find((u) => u._id === decrypt(arteId));
      if (foundArt) {
        setArt(foundArt);
        if (ratingLocalStorage) {
          setRating(ratingLocalStorage);
        }
        if (numberAvaliations) {
          setavaliations(numberAvaliations);
        }
      } else {
        // throw new Error("Arte não encontrada");
        // console.error("Arte não encontrada");
      }
    }
  }, [arteId, dadosArtes]);

  const onRatingChange = (newRating: number) => {
    localStorage.setItem(`rating ${decrypt(arteId as any)}`, String(newRating));
    setRating(rating);
  };

  const headerOrHeaderAdm = () => {
    const urlAtual = window.location.href;
    if (urlAtual.includes(`login`)) {
      return <HeaderLoginComponent />;
    }
    if (urlAtual.includes(`admuser`)) {
      return <HeaderAdmin />;
    }
    if (urlAtual.includes(`admartist`)) {
      return <HeaderArtist />;
    } else {
      return <Header />;
    }
  };

  return (
    <div>
      {headerOrHeaderAdm()}

      {art && !art?.pdf && (
        <div
          style={{
            width: "100%",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <h1
            style={{
              width: "300px",
              padding: "25px",
              borderRadius: "4px",
            }}
          >
            {art.nome_livro}
          </h1>
          <img
            src={art.foto}
            style={{
              width: "300px",
              height: "450px",
              borderRadius: "4px",
            }}
            alt=""
          />
          <h1>EM BREVE!!</h1>
        </div>
      )}

      <div className="content">
        {isLoading ? (
          <div>
            <div
              style={{
                position: "relative",
                // paddingTop: "max(15%,324px)",
                width: "100%",
                height: "0",
                maxWidth: "1200px",
                display: "flex",
                margin: "auto",
              }}
            >
              <iframe
                className="iframe"
                style={{
                  position: "absolute",
                  border: "none",
                  width: "100%",
                  height: "650px",
                  left: "0",
                  top: "0",
                }}
                src={art?.pdf}
                scrolling="no"
                frameBorder="0"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
      {art && (
        <div
          style={{
            textAlign: "center",
            // position: "absolute",
            zIndex: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            marginTop: "-10px",
            backgroundColor: "transparent !important",
            flexDirection: "column",
          }}
        >
          <Rating
            count={5}
            value={rating}
            onChange={onRatingChange}
            size={50}
            activeColor="#ff000083"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ArtPage;
